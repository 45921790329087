"use client"
import { usePathname, useRouter, useSearchParams } from "next/navigation"

const UseQueryParams = () => {
  const searchParams = useSearchParams()
  const { replace, refresh, push } = useRouter()
  const pathname = usePathname()

  const handleMultipleManipulateQuery = (isScroll = false, ...arr) => {
    const newSearchParams = new URLSearchParams(searchParams.toString())

    // Loop through the array of parameters and update or add them
    arr.forEach(([key, value]) => {
      // Update or add the parameter
      newSearchParams.set(key, value)
    })

    // Construct the new URL
    const updatedParams = newSearchParams.toString()
    const newUrl = updatedParams ? `${pathname}?${updatedParams}` : pathname

    // Update the URL and refresh
    push(newUrl, { scroll: isScroll })
  }

  const handleManipulateQuery = (
    addedName,
    addedValue,
    deleteName,
    isScroll = false
  ) => {
    const newSearchParams = new URLSearchParams(searchParams.toString())

    // Update or add the parameter
    newSearchParams.set(addedName, addedValue)

    // Remove the specified parameter
    newSearchParams.delete(deleteName)

    // Construct the new URL
    const updatedParams = newSearchParams.toString()
    const newUrl = updatedParams ? `${pathname}?${updatedParams}` : pathname

    // Update the URL and refresh
    push(newUrl, { scroll: isScroll })
  }

  const handleFilterQuery = (name, isScroll = true) => {
    const newSearchParams = new URLSearchParams(searchParams.toString())

    if (!newSearchParams.has(name)) return // Exit early if param doesn't exist

    newSearchParams.delete(name)
    const updatedSearchParams = newSearchParams.toString()
    const newUrl = updatedSearchParams
      ? `${pathname}?${updatedSearchParams}`
      : pathname

    console.log(newUrl)

    const currentUrl = `${pathname}${window.location.search}`
    const newUrlTest = `${pathname}?${newSearchParams.toString()}`

    console.log("Current URL:", currentUrl)
    console.log("New URL:", newUrlTest)

    if (currentUrl === newUrlTest) {
      console.warn("URL is unchanged, skipping navigation.")
      return // Exit early if the URL hasn't changed
    }

    push(newUrl, { scroll: isScroll })
  }

  const handlePushQuery = (isScroll = false, ...valueAdded) => {
    const newSearchParams = new URLSearchParams(searchParams)

    valueAdded.forEach((value) => {
      newSearchParams.set(value[0], value[1])
    })

    const finalSearchParamas = newSearchParams.toString()

    replace(
      `${pathname}${finalSearchParamas ? `?${finalSearchParamas}` : ""}`,
      { scroll: isScroll }
    )
  }

  return {
    handlePushQuery,
    handleFilterQuery,
    handleManipulateQuery,
    handleMultipleManipulateQuery,
  }
}

export default UseQueryParams
