"use client"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import React, { Suspense, useEffect, useState } from "react"
// import Link from 'next/link';
// Custom comp. imports
import TierCategories from "../user-profile/tier-categories/tier-categories"
import ExclusiveReward from "../user-profile/exclusive-reward/exclusive-reward"
import Vouchers from "../user-profile/vouchers/vouchers"
import LimitedItem from "../user-profile/limited-item/limited-item"
import UserChallenge from "../user-profile/user-challenge/user-challenge"
import { ClientApiList } from "@/lib/api/client-service"
import ButtonAction from "../shared/button-action/button-action"
import { getGALinker, getGL } from "@/helper/ga"
import PopupAction from "../shared/popup-action/popup-action"
import { Image } from "../shared/image/image"
import { Description } from "../shared/description/description"
import PopOut from "../shared/popup-out/popup-out"
import { cn } from "@/lib/utils"
import UseQueryParams from "@/hooks/use-query-params"
import { useIsLogin } from "@/hooks/auth/use-is-login"

const UseProfileRewardsFallback = () => {
  return (
    <div className="bg-bg-3 px-[15px] lg:px-[0] py-[20px] lg:py-[0] mt-[39px] lg:mt-[60px] max-w-[935px] lg:mx-auto rounded-t-[10px]">
      Fallback
    </div>
  )
}

export function SuspendedUseProfileRewards() {
  const QUERYPARAM = {
    aldmic: "show-popup-aldmic",
    aspace: "show-popup-aspace",
  }

  const { handleFilterQuery, handlePushQuery } = UseQueryParams()
  const searchParams = useSearchParams()
  const { replace } = useRouter()
  const { isLogin, isLoading: isLoginLoading } = useIsLogin()
  const pathname = usePathname()
  const isLoginComplete =
    isLogin && !isLoginLoading && pathname !== "/allaccess-loading"

  const [rewardBanners, setRewardBanners] = useState(null)
  const [selectedId, setSelectedId] = useState()
  const [selectedItemData, setSelectedItemData] = useState()
  const [selectedVoucher, setSelectedVoucher] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [aldmic, setAldmic] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isAldmicLoading, setIsAldmicLoading] = useState(true)
  const [isLoadMore, setIsLoadMore] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get("reward-tier") || "Bronze"
  )
  const currentPage = searchParams.get("user-challenge-page") || 0
  const [allChallengesList, setAllChallengesList] = useState([])
  const [allChallengesPaging, setAllChallengesPaging] = useState({})
  // const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAspacePopupOpen, setIsAspacePopupOpen] = useState(false)
  const [isItemClaimed, setIsItemClaimed] = useState(false)
  const [isLoyaltyActive, setIsLoyaltyActive] = useState(true)
  const [responseAldmic, setResponseAldmic] = useState(false)
  const [failedAuth, setFailedAuth] = useState(false)
  const [isPopupAldmicOpen, setIsPopupAldmicOpen] = useState(false)

  const getParamsAldmic = searchParams.get("to") === "aldmic"
  const getParamsId = searchParams.get("id")

  const handleSource = () => {
    switch (process.env.NEXT_PUBLIC_NAME) {
      case "amild":
        return "a-mild"
      case "djisamsoe":
        return "dss"
      case "sampoernakretek":
        return "sah"
      default:
        return process.env.NEXT_PUBLIC_NAME
    }
  }

  const getAuthData = async () => {
    const {
      data: { data: auth_data },
    } = await ClientApiList.getAuthData({
      ga: getGALinker(),
      gl: getGL(),
      platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
      redirect_url: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
    })
    return auth_data
  }

  const getAspaceToken = async (auth_data) => {
    const { data: dataToken } = await ClientApiList.getAspaceToken({
      auth_data: auth_data.data.auth_data,
    })
    return dataToken
  }

  const handleGetAllChallenges = async () => {
    setIsLoadMore(true)
    try {
      const { data } = await ClientApiList.getCampaign()
      // console.log(data, "data a", allChallengesList.length > 0, data.data.paging.currPage, allChallengesPaging.currPage)
      // if (
      //   allChallengesList.length > 0 &&
      //   data.data.paging.currPage === allChallengesPaging.currPage
      // ) {
      //   setIsLoadMore(false);
      //   return;
      // }
      setAllChallengesList([...data.data.data])
      // setAllChallengesPaging({ ...data.data.paging });
      setIsLoadMore(false)
    } catch (err) {
      console.log("[Error]", err)
    }
  }

  const handleGetVouchers = async () => {
    try {
      // console.log(rewardBanners?.categories.find(cat => cat.label === selectedCategory).id, 'tier fetch', selectedCategory);
      const tier = !rewardBanners?.categories
        ? 1
        : rewardBanners.categories.find((cat) =>
            searchParams.get("reward-tier")
              ? cat.label === searchParams.get("reward-tier")
              : cat.label === selectedCategory
          )?.id

      const { data } = await ClientApiList.getUserVouchers({
        tier,
        platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
        source: handleSource(),
      })

      setAldmic({ ...data.data.data })
      setIsAldmicLoading(false)
    } catch (err) {
      throw Error(err)
    }
  }

  const handleGetRewardBanners = async () => {
    try {
      const { data: loyaltyData } = await ClientApiList.getLoyaltyTeaser()
      // console.log(loyaltyData.data.data, "data loyalty")
      setSelectedCategory(loyaltyData.data.data.current_tier)
      setIsLoyaltyActive(
        Object.prototype.hasOwnProperty.call(
          loyaltyData.data.data,
          "accumulated_exp"
        )
      )
      const { data } = await ClientApiList.getUserRewardList()
      setRewardBanners({ ...data.data.data })
      setIsLoading(false)
    } catch (err) {
      throw Error(err)
    }
  }

  const sendFormPost = (
    { path, target, params, method = "post" },
    callback
  ) => {
    return new Promise((resolve, reject) => {
      const form = document.createElement("form")
      form.target = target
      form.method = method
      form.action = path

      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const hiddenField = document.createElement("input")
          hiddenField.type = "hidden"
          hiddenField.name = key
          hiddenField.value = params[key]
          form.appendChild(hiddenField)
        }
      }
      document.body.appendChild(form)
      form.submit(callback)
      setTimeout(() => {
        resolve("success")
        document.body.removeChild(form)
      }, 500)
    })
  }

  // https://loyalty-dev.aldmic.com/one-ux/leaderboard

  const handleClaim = async () => {
    const auth_data = await getAuthData()
    setResponseAldmic(auth_data.data.auth_data)

    let source = handleSource()
    const params = { source, auth_data: auth_data.data.auth_data }
    params[`voucher_id`] = getParamsAldmic ? getParamsId : selectedId

    if (auth_data.data.auth_data) {
      setTimeout(() => {
        const response = sendFormPost(
          {
            path:
              process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
                ? `https://loyalty.aldmic.com/one-ux${getParamsAldmic ? "/leaderboard" : "/voucher"}`
                : `https://loyalty-dev.aldmic.com/one-ux${getParamsAldmic ? "/leaderboard" : "/voucher"}`,
            target: "_self",
            params,
          }
          // (response) => console.log(response, "res")
        )
        setIsPopupAldmicOpen(false)
        // handleFilterQuery(QUERYPARAM.aldmic)
        // replace(pathname + "?" + paramsquery, { scroll: false });
      }, 1500)
    } else {
      console.log("failed")
      setFailedAuth(true)
    }
  }

  useEffect(() => {
    if (isLoginComplete) handleGetRewardBanners()
  }, [isLoginComplete])

  // console.log(isLoyaltyActive, "loyalty active")

  const handleItemClaim = async (
    aldmic_id,
    isVoucher,
    itemUrl,
    itemData,
    id
  ) => {
    setSelectedId(aldmic_id)
    setSelectedVoucher(isVoucher)
    setSelectedItem(itemUrl)
    setSelectedItemData(itemData)

    // console.log(id, isVoucher, itemUrl, itemData, 'data')

    if (!isVoucher && itemData?.is_aspace) {
      const auth_data = await getAuthData()
      const dataToken = await getAspaceToken(auth_data)
      const { data: dataClaimed } = await ClientApiList.getAspaceClaim({
        token: dataToken.data.token,
        item_id: id,
      })
      setIsItemClaimed(dataClaimed.data.claim_status)

      // handlePushQuery(false, [QUERYPARAM.aspace, true])
      setIsAspacePopupOpen(true)
    } else {
      setIsPopupAldmicOpen(true)
      // handlePushQuery(false, [QUERYPARAM.aldmic, true])
    }
  }

  const handleRedirectASpace = async () => {
    const auth_data = await getAuthData()
    const tokenData = await getAspaceToken(auth_data)
    const redirectASpace = tokenData?.data.redirect_url

    if (redirectASpace) {
      window
        .open(
          redirectASpace,
          "_self"
          // "_blank" // <- This is what makes it open in a new window.
        )
        .focus()
      replace(pathname)
    }
  }

  useEffect(() => {
    if (searchParams.get("reward-tier"))
      setSelectedCategory(searchParams.get("reward-tier"))
    // if (searchParams.get("show-popup-aldmic")) setIsPopupOpen(true);
    // if (searchParams.get("show-popup-aspace")) setIsAspacePopupOpen(true);
    if (
      // !searchParams.get("show-popup-aldmic") &&
      // !searchParams.get("show-popup-aspace")
      !isAspacePopupOpen &&
      !isPopupAldmicOpen
    ) {
      setSelectedId(null)
      setSelectedItem(null)
      setSelectedVoucher(false)
      setIsPopupAldmicOpen(false)
      setIsAspacePopupOpen(false)
      setSelectedItemData(null)
      // setIsPopupOpen(false);
    }
    handleGetAllChallenges()
    rewardBanners && handleGetVouchers()
  }, [searchParams, rewardBanners])

  function handleRenderList(aldmic, name) {
    for (const list in aldmic) {
      // console.log(aldmic[list], aldmic, 'loop')
      if (String(aldmic[list].category_name).toLowerCase() === name) {
        // console.log(aldmic[list].list_voucher)
        return aldmic[list].list_voucher
      }
    }
  }

  aldmic && handleRenderList(aldmic)

  // console.log(aldmic, 'aldmic');
  const vouchers = !aldmic ? [] : handleRenderList(aldmic, "exclusive voucher")
  const items = !aldmic ? [] : handleRenderList(aldmic, "limited items")

  /* redirect to aldmic params */
  useEffect(() => {
    if (getParamsAldmic && getParamsId) {
      setTimeout(() => handleClaim(), 2000)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getParamsAldmic, getParamsId])

  // console.log(selectedItem, selectedId, selectedVoucher, selectedItemData, 'spaces,', isPopupOpen, isAspacePopupOpen)
  return (
    <>
      <PopOut
        isPopupOpen={isPopupAldmicOpen}
        handleClaim={handleClaim}
        selectedItem={selectedItem}
        auth={responseAldmic}
        failedAuth={failedAuth}
        handleClose={setIsPopupAldmicOpen}
      />
      <PopupAction
        isShowing={isAspacePopupOpen}
        isScroll={false}
        zIndex="z-20"
        href={pathname}
        onClickBtnX={() => setIsAspacePopupOpen(false)}
      >
        <div className="max-w-[304px] mx-auto">
          {/* <div className="absolute rounded-[10px] border-[3px] border-dotted inset-[15px] z-[-1]"></div> */}
          <div className="h-full relative aspect-[2/1]">
            <Image alt="banner" src={selectedItemData?.img} />
          </div>
          <div className="mx-auto my-[20px] w-[304px]">
            <div className="text-xl font-black font-font-family-8 mb-[5px] text-text-2">
              {isItemClaimed
                ? `Selamat, Lo Bisa Dapetin ${selectedItemData?.title}`
                : "Oops, Lo Belom klaim Item ini di A Space"}
            </div>
            <Description
              className="text-sm font-medium font-font-family-6 text-text-2"
              description={
                isItemClaimed
                  ? "Lo sudah klaim digital item ini di A Space dan lo bisa dapetin physical item-nya! Buruan klaim reward lo sekarang."
                  : "Klaim item ini terlebih dahulu di A Space untuk dapetin physical item-nya. Segera klaim sekarang sebelum kehabisan!"
              }
            />
          </div>
          <div className="flex items-center justify-between gap-[10px]">
            <ButtonAction
              className="py-[15px] px-[20px] leading-[20px]"
              intent="primary"
              onClick={() => {
                if (isItemClaimed) {
                  handleClaim()
                } else {
                  handleRedirectASpace()
                }
              }}
            >
              {isItemClaimed ? "Klaim" : "Klaim di A Space"}
            </ButtonAction>
          </div>
        </div>
      </PopupAction>
      <div
        className={cn(
          "w-full overflow-hidden px-[10px] md:px-[20px] lg:px-[0] py-[20px] lg:py-[0] mt-[39px] lg:mt-[60px] lg:max-w-[935px] lg:mx-auto rounded-t-[10px]",
          process.env.NEXT_PUBLIC_NAME === "amild" ? "bg-bg-3" : ""
        )}
      >
        {isLoyaltyActive ? (
          <>
            <ExclusiveReward
              setSelectedItem={handleItemClaim}
              isLoading={isLoading}
              rewardItems={rewardBanners?.rewards}
            />
            <TierCategories
              isLoading={isLoading}
              categories={rewardBanners?.categories}
              selectedCategory={selectedCategory}
            />
            <Vouchers
              vouchers={vouchers}
              isLoading={isAldmicLoading}
              handleClaim={handleItemClaim}
            />
            <LimitedItem
              limitedItems={items}
              isLoading={isAldmicLoading}
              handleClaim={handleItemClaim}
            />
          </>
        ) : null}
        <UserChallenge
          isLoadMore={isLoadMore}
          challengeItems={allChallengesList}
          challengePaging={allChallengesPaging}
        />
      </div>
    </>
  )
}

export function UserProfileRewards(props) {
  return (
    <Suspense fallback={<UseProfileRewardsFallback />}>
      <SuspendedUseProfileRewards {...props} />
    </Suspense>
  )
}
