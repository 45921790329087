"use client"
import Link from "@/components/shared/link/link"
import styles from "./syaratKetentuan.module.scss"
import ButtonAction from "@/components/shared/button-action/button-action"
import clsx from "clsx"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname } from "next/navigation"

export default function CampaignSnkMagnum() {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const back = pathname.split("/")[1]

  return (
    <section
      className={clsx(
        styles.container,
        "w-full max-w-[460px] mx-auto relative py-[30px] text-text-2 text-xs",
        isMobile && "px-3.5"
      )}
    >
      {/* <div className={styles.snk_bg}>
        <Image
          alt="bg"
          objectPosition="top left"
          src={isMobile ? snkBgMob : snkBgDesk}
          layout="fill"
          objectFit="contain"
        />
      </div> */}
      {/* <div className={styles.snk_back}>
        <Link href={prevPath || back} passHref>
          <ButtonBack />
        </Link>
      </div> */}
      <div className={styles.snk}>
        <h2
          className={clsx(
            styles.snk_title,
            "font-bold text-2xl text-text-2 capitalize font-font-family-3"
          )}
        >
          HARI BESAR MUSIK KERAS 2025
        </h2>
        <p
          className={clsx(
            styles.snk_title,
            "font-bold text-2xl text-text-2 capitalize font-font-family-3"
          )}
        >
          SYARAT & KETENTUAN
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          <strong>PENTING:</strong> Harap baca syarat dan ketentuan ini (“Syarat
          & Ketentuan”) sebelum mengikuti program ini. Dengan mengikuti Program
          ini, anda mengkonfirmasi bahwa anda telah membaca, memahami dan setuju
          untuk mematuhi Syarat & Ketentuan termasuk perubahannya yang dibuat
          oleh pihak Penyelenggara dari waktu ke waktu.
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          Hari Besar Musik Keras adalah program konsumen yang ditujukan kepada
          perokok dewasa milik PT HM Sampoerna Tbk. (“Sampoerna”). Sampoerna
          merupakan produsen, penjual, pemasar serta afiliasi dari produsen yang
          bergerak di bidang bisnis tembakau dan produk yang mengandung nikotin
          lainnya. Kegiatan ini meliputi riset dan pengembangan dan/atau
          distribusi produk rokok, berbagai hasil tembakau lainnya yang dibakar
          maupun yang tidak dibakar, atau rokok elektronik/<em>e-vapors</em>{" "}
          (alat yang memproduksi aerosol dengan menguapkan larutan nikotin
          berperisa dan menggunakan energi baterai), serta produk mengandung
          nikotin lainnya yang dipasarkan dengan merek-merek dagang tertentu,
          termasuk merek Magnum (“Program”). Sampoerna menunjuk pihak ketiga
          dalam untuk menyelenggarakan acara sebagai bagian dari pemberian
          penghargaan atau <em>reward</em> dan pelaksanaan rangkaian program
          (“Penyelenggara”).
        </p>
        <p
          className={clsx(
            styles.snk_sub,
            "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
          )}
        >
          Perokok dewasa yang berpartisipasi dalam Program ini, wajib membaca,
          memahami, dan mematuhi Syarat & Ketentuan yang diuraikan di bawah ini.
        </p>
        <div className={styles.snk_desc}>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              A. Persyaratan Peserta mengikuti Program:
            </span>
            <p
              className={clsx(
                styles.snk_sub,
                "font-medium font-font-family-6 mt-[10px] text-text-2 text-sm"
              )}
            >
              Program ini berlaku di wilayah Republik Indonesia dan terbuka bagi
              setiap orang Peserta wajib memenuhi persyaratan Program sebagai
              berikut:
            </p>
            <ul className={styles.snk_tab}>
              <li>
                Peserta merupakan warga negara Indonesia, baik individu maupun
                kelompok, dan bertempat tinggal di wilayah Indonesia.
              </li>
              <li>
                Seluruh peserta, baik individu maupun kelompok, adalah perokok
                dewasa berusia minimal 21 tahun, dan memiliki identitas berupa
                KTP yang masih berlaku.
              </li>
              <li>Terdaftar dan memiliki akun pada website www.magnum.id.</li>
              <li>Peserta sudah melengkapi profil akun di www.magnum.id.</li>
              <li>
                Peserta memiliki kemampuan secara fisik dan mental untuk
                mengikuti Program.
              </li>
              <li>
                Program ini berlaku di seluruh Indonesia sesuai dengan Periode
                Program.
              </li>
              <li>
                Individu atau kelompok yang berpartisipasi dalam Program Hari
                Besar Musik Keras serta memenuhi Syarat & Ketentuan ini,
                selanjutnya disebut “Peserta”.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              B. Periode Program:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                Program ini dimulai sejak 3 Maret dan berakhir pada 25 Agustus
                2025 (“Periode Program”).
              </li>
              <li>
                Setiap karya yang tidak diterima Penyelenggara selama Periode
                Program akan secara otomatis didiskualifikasi dari partisipasi.
                Penyelenggara tidak bertanggung jawab atas karya yang hilang,
                tertunda, atau gagal terkirim.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              C. Mekanisme Program:
            </span>
            <ul className={styles.snk_tab}>
              <li>
                <u>Pendaftaran Akun</u>. Peserta wajib memiliki akun dan
                terdaftar pada situs www.magnum.id.
              </li>
              <li>
                <u>Perwakilan Kelompok</u>. Kelompok Peserta harus menunjuk satu
                orang sebagai perwakilan yang akan menjadi titik kontak selama
                pelaksanaan Program.
              </li>
              <li>
                <u>Cara Pendaftaran</u>. Pendaftaran dapat dilakukan secara
                mandiri melalui situs resmi www.magnum.id atau di lokasi acara (
                <em>venue</em>) yang bekerjasama dengan Magnum.
              </li>
              <li>
                <u>Pengiriman Karya Musik</u>. Peserta wajib mengirimkan karya
                musik orisinil untuk mengikuti Program yang diselenggarakan
                melalui website www.magnum.id.
              </li>
              <li>Submisi Hari Besar Musik Keras</li>
              <span className="flex my-2 ml-[5px] font-bold font-font-family-7 text-text-2 text-sm">
                Band Submission:
              </span>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Karya musik atau demo yang dikirimkan harus berukuran maksimal
                10MB, dengan format Mp3, atau WAV.
              </li>
              <li>
                Karya harus dilengkapi dengan profil band/musisi dan deskripsi
                singkat mengenai karya, termasuk genre dan sub-genre.
              </li>
              <li>
                Keaslian dan orisinalitas hasil karya (musik, lirik,{" "}
                <em>artwork cover</em>) harus dapat dipertanggungjawabkan oleh
                Peserta. Karya tidak boleh menjiplak hasil karya orang lain yang
                dimodifikasi atau menggunakan elemen yang melanggar hak cipta
                serta etika pembuatan karya cipta. Jika terbukti terjadi
                pelanggaran ketentuan, hasil karya akan dianulir atau dibatalkan
                Penyelenggara.
              </li>
              <li>
                Peserta harus memastikan bahwa karya yang dikirimkan tidak
                mengandung unsur-unsur yang dapat dianggap ofensif,
                diskriminatif, atau melanggar norma-norma kesopanan dan
                kesusilaan. Untuk mematuhi regulasi, menjaga netralitas dan
                sensitivitas, serta memastikan etika dan tanggung jawab sosial,
                karya tidak boleh memuat lirik atau visual yang dapat menarik
                perhatian anak di bawah umur (seperti tokoh kartun, mainan,
                pendidikan, dsb.), tidak dikaitkan dengan kesehatan, olahraga,
                agama, politik, budaya/adat daerah, dan tidak menampilkan bentuk
                rokok (bungkus/batang/asap) serta brand/logo/simbol
                produk/organisasi/gerakan kemasyarakatan tertentu.
              </li>
              <li>
                Seluruh hak cipta dan <em>master file</em> karya musik yang
                dikirimkan merupakan hak milik Peserta.
              </li>
              <li>
                Peserta bertanggung jawab untuk memastikan bahwa karya yang
                dikirimkan tidak melanggar hak-hak pihak ketiga, termasuk tetapi
                tidak terbatas pada hak cipta, merek dagang, dan hak privasi.
              </li>
              <li>
                Peserta harus memastikan bahwa karya yang dikirimkan tidak
                mengandung unsur-unsur yang dapat dianggap merugikan atau
                mencemarkan nama baik pihak lain.
              </li>
            </ul>
            <ul className={styles.snk_tab}>
              <span className="flex my-2 ml-[5px] font-bold font-font-family-7 text-text-2 text-sm">
                Artwork Submission:
              </span>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Karya visual yang dikirimkan harus berukuran maksimal 1 MB
                dengan format JPG atau JPEG atau PNG.
              </li>
              <li>Setiap desain harus mencakup judul dan keterangan</li>
              <li>
                Setiap desain menggabungkan warna dominan (Hitam, Putih,
                Abu-abu, <em>Silver</em>, dan <em>Chrome</em> dengan pantone
                yang disediakan)
              </li>
            </ul>
            <ul className={styles.snk_tab3}>
              <li>Pantone Warna:</li>
            </ul>
            <ul className={styles.snk_tab4}>
              <li>
                Hitam: RGB (R0 G0 B0) | CMYK (C0 M0 Y0 K100) | Abu-abu: RGB (R64
                G64 B64) | CMYK (C0 M0 Y0 K75)
              </li>
              <li>
                <em>Silver</em>: RGB (R200 G200 B200) | CMYK (C21 M17 Y17 K0) |
                Putih: RGB (R255 G255 B255) | CMYK (C0 M0 Y0 K0)
              </li>
              <li>
                <em>Chrome</em>: Gradasi Grayscale K10% #bfbfbfff – K50%
                #737373ff
              </li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Keaslian dan orisinalitas hasil karya (foto, vektor, ilustrasi,
                desain, dan objek visual lainnya) harus dapat
                dipertanggungjawabkaan oleh peserta. Karya tidak boleh menjiplak
                hasil karya orang lain yang dimodifikasi atau menggunakan elemen
                yang melanggar hak cipta serta etika pembuatan karya cipta. Jika
                terbukti terjadi pelanggaran ketentuan, hasil karya akan
                dianulir atau dibatalkan Penyelenggara.
              </li>
              <li>
                Peserta harus memastikan bahwa karya yang dikirimkan tidak
                mengandung unsur-unsur yang dapat dianggap ofensif,
                diskriminatif, atau melanggar norma-norma kesopanan dan
                kesusilaan. Untuk mematuhi regulasi, menjaga netralitas dan
                sensitivitas, serta memastikan etika dan tanggung jawab sosial,
                karya tidak boleh memuat lirik atau visual yang dapat menarik
                perhatian anak di bawah umur (seperti tokoh kartun, mainan,
                pendidikan, dsb.), tidak dikaitkan dengan kesehatan, olahraga,
                agama, politik, budaya/adat daerah, dan tidak menampilkan bentuk
                rokok (bungkus/batang/asap) serta brand/logo/simbol
                produk/organisasi/gerakan kemasyarakatan tertentu.
              </li>
              <li>
                Seluruh hak cipta dan <em>master file</em> karya musik yang
                dikirimkan merupakan hak milik Peserta.
              </li>
              <li>
                Peserta bertanggung jawab untuk memastikan bahwa karya yang
                dikirimkan tidak melanggar hak-hak pihak ketiga, termasuk tetapi
                tidak terbatas pada hak cipta, merek dagang, dan hak privasi.
              </li>
              <li>
                Peserta harus memastikan bahwa karya yang dikirimkan tidak
                mengandung unsur-unsur yang dapat dianggap merugikan atau
                mencemarkan nama baik pihak lain.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <p
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              D. Rewards:
            </p>
            {/* <span
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              Hadiah Utama:
            </span> */}
            <ul className={styles.snk_tab}>
              <li>
                Peserta yang sudah terdaftar dan mengikuti program Hari Besar
                Musik Keras di www.magnum.id akan mendapatkan kesempatan untuk
                memperoleh salah satu atau lebih penghargaan dari rangkaian Hari
                Besar Musik Keras (“Reward”), yaitu:
              </li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Menjadi penampil di showcase, gigs, dan/atau festival yang
                berkolaborasi dengan Magnum.
              </li>
              <li>
                Karya visual terbaik yang sudah terkurasi dan melalui proses
                vote di magnum.id akan digunakan menjadi official merchandise
                magnum.
              </li>
            </ul>
            <ul className={styles.snk_tab}>
              <li>
                Peserta terpilih yang berkesempatan menjadi penampil di acara
                sebagaimana dimaksud dalam angka 1 akan diminta meninjau dan
                menyetujui segala syarat dan ketentuan yang berlaku di acara
                terkait.
              </li>
              <li>
                Peserta terpilih, yang karya visualnya berkesempatan menjadi
                merchandise resmi sebagaimana dimaksud dalam angka 2 akan
                diminta meninjau dan menyetujui segala syarat dan ketentuan yang
                berlaku.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <p
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              E. Ketentuan Peserta Terpilih:
            </p>
            <ul className={styles.snk_tab}>
              <li>Penentuan Peserta Terpilih</li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Penentuan dilakukan melalui proses seleksi berdasarkan ketentuan
                yang telah tertera dalam Syarat & Ketentuan.
              </li>
              <li>
                Kandidat Peserta terpilih diwajibkan mengikuti proses kurasi
                dan/atau wawancara oleh Penyelenggara, sebagai proses penentuan
                peserta terpilih atau pemenang.
              </li>
            </ul>
            <ul className={styles.snk_tab}>
              <li>Pengumuman Reward</li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Peserta terpilih melalui proses kurasi akan diumumkan pada bulan
                Agustus 2025.
              </li>
              <li>
                Pengumuman dilakukan Penyelenggara melalui situs dan e-mail
                konfirmasi resmi www.magnum.id.
              </li>
            </ul>
            <ul className={styles.snk_tab}>
              <li>
                Reward tidak dapat dialihkan, dan/atau tidak dapat ditukarkan
                dengan uang tunai atau bentuk apapun, baik secara keseluruhan
                atau sebagian.
              </li>
              <li>
                Peserta yang menjadi Pemenang dengan sendirinya dinyatakan
                didiskualifikasi apabila:
              </li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Peserta tidak dapat dihubungi oleh Penyelenggara untuk keperluan
                verifikasi dalam jangka waktu 2x24 jam sejak pengumuman Peserta
                terpilih.
              </li>
              <li>
                Peserta telah terpilih oleh Penyelenggara dalam Program yang
                sama pada periode pengumuman sebelumnya.
              </li>
              <li>
                Peserta terpilih tidak dapat menunjukkan kelengkapan dokumen
                pendukung pada saat proses verifikasi, seperti Kartu Tanda
                Penduduk (KTP), dan dokumen lainnya untuk memenuhi persyaratan
                administrasi yang berlaku sesuai jangka waktu yang diberikan
                oleh Penyelenggara.
              </li>
            </ul>
            <ul className={styles.snk_tab}>
              <p className="ml-[5px]">
                Dengan ini, Peserta menyatakan setuju untuk melepaskan haknya
                sebagai Peserta terpilih untuk menerima Reward dan akan
                membebaskan Penyelenggara dari klaim dan/atau tuntutan
                penyerahan Hadiah dalam bentuk apapun di masa mendatang.
              </p>
            </ul>
            <ul className={styles.snk_tab}>
              <li>Verifikasi dan Validasi</li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Penyelenggara berhak melakukan verifikasi dan/atau pengecekan
                validasi data Peserta serta: (i) mendiskualifikasi Peserta
                terpilih; dan/atau (ii) menolak atau membatalkan pemberian
                Reward, apabila Peserta diduga melakukan kecurangan atau
                pelanggaran Syarat & Ketentuan ini dalam bentuk apapun.
              </li>
              <li>
                Keputusan Penyelenggara bersifat mutlak dan final sehingga tidak
                dapat diganggu gugat.
              </li>
            </ul>
            <ul className={styles.snk_tab}>
              <li>
                Peserta terpilih sebagai penampil di showcase, gigs, dan/atau
                festival yang berkolaborasi dengan Hari Besar Musik Keras wajib
                mengikuti syarat dan ketentuan dari pihak penyelenggara acara
                terkait.
              </li>
            </ul>
          </div>
          <div className={styles.snk_part}>
            <p
              className={clsx(
                styles.snk_content,
                "font-bold font-font-family-7 text-text-2 text-sm"
              )}
            >
              F. Ketentuan Umum:
            </p>
            <ul className={styles.snk_tab}>
              <li>
                Program ini tidak berlaku untuk semua karyawan, keluarga
                karyawan, dan/atau pihak ketiga yang terikat kerja sama dengan
                PT HM Sampoerna Tbk dan/atau afiliasinya.
              </li>
              <li>
                Penyelenggara berhak untuk mengubah tanggal, mekanisme, Reward
                dan/atau Syarat & Ketentuan atas Program dari waktu ke waktu
                sepanjang konsep dan nilainya kurang lebih sama dengan yang
                diuraikan saat ini, tanpa pemberitahuan sebelumnya atau
                kompensasi dalam bentuk apapun kepada para Peserta dan/atau
                pihak manapun.
              </li>
              <li>
                Dalam hal terjadi peristiwa keadaan memaksa (
                <em>force majeure</em>) atau pertimbangan material lainnya,
                Penyelenggara dapat membatalkan penyelenggaraan Program tanpa
                kewajiban untuk memberikan pemberitahuan dan/atau kompensasi
                kepada Peserta atau pihak manapun.
              </li>
              <li>
                Penyelenggara tidak akan bertanggung jawab apabila terjadi
                penipuan dan/atau tindak pidana lainnya yang mengatasnamakan
                Program ini. Untuk itu, Peserta diminta untuk berhati-hati dan
                waspada terhadap modus penipuan dan/atau tindak pidana lainnya
                yang mungkin mengatasnamakan Program ini serta selalu memastikan
                berhubungan dengan pihak Penyelenggara yang sah terkait Program.
              </li>
              <li>
                Silakan hubungi atau periksa pihak Penyelenggara yang sah
                terkait Program pada website amild.id atau email ke{" "}
                <span className="text-text-1">info@magnum.id</span>.
              </li>
              <li>
                Setiap Peserta yang mengikuti Program ini menjamin bahwa setiap
                informasi yang diberikan adalah benar, tepat dan akurat selama
                periode Program.
              </li>
              <li>
                Dengan mengikuti Program ini, Peserta setuju atas dan memberikan
                izin kepada PT HM Sampoerna Tbk. dan/atau afiliasinya untuk:
              </li>
            </ul>
            <ul className={styles.snk_tab2}>
              <li>
                Memperoleh, mengumpulkan, mengolah, menganalisis, menyimpan,
                menampilkan, mengumumkan, mengirimkan, mempublikasikan dan/atau
                menyebarkan data pribadi Peserta dan setiap Pemenang (antara
                lain namun tidak terbatas kepada, informasi nama, foto, nomor
                identitas, email, nomor telepon) namun terbatas untuk keperluan
                verifikasi Peserta;
              </li>
              <li>
                Menghubungi Peserta secara langsung ataupun melalui pihak ketiga
                yang telah ditunjuk;
              </li>
              <li>
                Mengungkapkan informasi data pribadi Peserta kepada PT HM
                Sampoerna Tbk. dan/atau afiliasinya untuk melakukan hal-hal di
                atas serta kepada pihak yang berwenang (jika disyaratkan oleh
                hukum yang berlaku di Negara Republik Indonesia); dan/atau
              </li>
              <li>
                Membuat dokumentasi dalam format apapun (foto atau video)
                rekaman video yang berisi antara lain, video, nama, dan komentar
                dari Peserta atau Pemenang untuk keperluan publikasi di dalam
                situs www.magnum.id atau materi publikasi lainnya dari Magnum
                dan PT HM Sampoerna Tbk. Dengan ini, Peserta menyetujui bahwa
                segala hak ekonomis yang mungkin timbul dari publikasi tersebut
                sepenuhnya menjadi milik Penyelenggara, dan
              </li>
              <li>
                Peserta tidak dapat menuntut hak ekonomis apapun yang mungkin
                timbul dari publikasi dimaksud.
              </li>
            </ul>
            <ul className={styles.snk_tab}>
              <li>
                Seluruh Hak Cipta dan Hak atas Kekayaan Intelektual (HAKI)
                termasuk <em>master file</em> dari karya musik yang dikirimkan
                oleh Peserta ke website www.magnum.id merupakan hak milik
                Peserta.
              </li>
            </ul>
          </div>
        </div>
        <Link href={`/${back}/br`} passHref>
          <ButtonAction
            intent="primary"
            className={styles.snk_ctaBtn}
            fullwidth
          >
            Kembali
          </ButtonAction>
        </Link>
      </div>
    </section>
  )
}
