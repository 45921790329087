import { useState, useEffect } from "react"

const useIsInIframe = () => {
  const [isInIframe, setIsInIframe] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsInIframe(window.self !== window.top)
    }
  }, [])

  return isInIframe
}

export default useIsInIframe
