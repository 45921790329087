import { cn } from "@/lib/utils"
import PopupAction from "../popup-action/popup-action"
import { Image } from "@/components/shared/image/image"
import {
  useIsDesktop,
  useIsMobile,
  useSmallHeight,
} from "@/hooks/use-is-mobile"
import ButtonAction from "../button-action/button-action"
import { useRouter } from "next/navigation"
import PropTypes from "prop-types"
import { urlAsset } from "@/helper/utils"

const PopupPoint = ({
  isShowing,
  onClose,
  title,
  description,
  point,
  btnPrimary,
  btnPrimaryUrl = "/br",
  btnSecondary,
  btnSecondaryUrl = "/br",
}) => {
  const isDesktop = useIsDesktop()
  const isSmallHeight = useSmallHeight()
  const isMobile = useIsMobile()
  const { replace } = useRouter()

  const handleClickButton = (url) => {
    replace(url)
    onClose()
  }

  return (
    <PopupAction
      isShowing={isShowing}
      onClickBtnX={onClose}
      href="/"
      padding="p-[10px]"
      zIndex="z-[20]"
    >
      <div className="w-full h-full p-[15px] rounded-[10px] border-[2px] border-cta-3 border-dashed">
        <div
          className={cn(
            "h-full relative aspect-[304/177] mx-auto",
            isSmallHeight && "w-[200px]",
            isMobile && !isSmallHeight && "w-[250px]"
          )}
        >
          <Image
            alt="Congrats"
            objectFit="cover"
            src={`${urlAsset()}/congrats.png`}
          />
        </div>
        <div className="my-[20px] max-w-[304px]">
          <p className="text-xl font-black font-font-family-8 mb-[5px] text-text-2 capitalize">
            {title}
          </p>
          {point > 0 && (
            <div className="flex items-center gap-[5px] justify-center mb-[5px]">
              <div className="min-h-[20px] w-fit min-w-[20px] relative">
                <Image
                  alt="cover"
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    position: "absolute",
                    inset: 0,
                  }}
                  src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
                />
              </div>
              <div className="text-[20px] text-text-1 leading-[27px] font-black font-font-family-8">
                {point} Poin!
              </div>
            </div>
          )}
          <p
            className={cn(
              "text-center font-medium font-font-family-6 text-text-2",
              isDesktop ? "text-base" : "text-sm"
            )}
          >
            {description}
          </p>
        </div>
        <div className="flex flex-col gap-[5px]">
          {btnPrimary ? (
            <ButtonAction
              onClick={() => handleClickButton(btnPrimaryUrl)}
              className="py-[15px] px-[20px]"
            >
              {btnPrimary}
            </ButtonAction>
          ) : null}
          {btnSecondary ? (
            <ButtonAction
              data-testid="popup-cancel"
              onClick={() => handleClickButton(btnSecondaryUrl)}
              className="py-[15px] px-[20px]"
              intent="secondary"
            >
              {btnSecondary}
            </ButtonAction>
          ) : null}
        </div>
      </div>
    </PopupAction>
  )
}
PopupPoint.propTypes = {
  isShowing: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  point: PropTypes.number,
  btnPrimary: PropTypes.string,
  btnPrimaryUrl: PropTypes.string,
  btnSecondary: PropTypes.string,
  btnSecondaryUrl: PropTypes.string,
}

export default PopupPoint
