"use client"
import React, { useEffect, useRef, useState } from "react"
import { ClientApiList } from "@/lib/api/client-service"
import crypto from "crypto"
import { ToastComponent } from "../toast"
import { useDatalayer } from "@/hooks/use-datalayer"
import UseQueryParams from "@/hooks/use-query-params"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { Spinner } from "../shared/spinner"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import Cookies from "js-cookie"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { useLogout } from "@/hooks/auth/use-logout"
import PopupAction from "../shared/popup-action/popup-action"
import { cn } from "@/lib/utils"
import { Description } from "../shared/description/description"
import ButtonAction from "../shared/button-action/button-action"
import { TokenApiList } from "@/lib/api/token-service"
import { RootService } from "@/lib/api/root-service"
import { postMessage } from "@/helper/utils"

export const encryptGame = (data) => {
  let dechiper = crypto.createCipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "utf8", "hex")
  dec += dechiper.final("hex")
  return dec
}

export const decryptData = (data) => {
  let dechiper = crypto.createDecipheriv(
    "aes-256-ctr",
    process.env.NEXT_PUBLIC_GAME_KEY,
    process.env.NEXT_PUBLIC_GAME_IV
  )
  let dec = dechiper.update(data, "hex", "utf8")
  dec += dechiper.final("utf8")
  return JSON.parse(dec)
}

export const IframeRedland = ({ page_uuid }) => {
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const datalayer = useDatalayer()
  const [point, setPoint] = useState()
  const pathname = usePathname()
  const [isSuccess, setIsSuccess] = useState(false)
  const [url, setUrl] = useState(true)
  const { isLogin, isLoading } = useIsLogin()
  const [isLoadingAuth, setIsLoadingAuth] = useState(true)
  const [isShowSpinner, setIsShowSpinner] = useState(false)
  const { replace } = useRouter()
  const iframeRef = useRef(null)
  const searchParams = useSearchParams()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isLoadingAccess, setIsLoadingAccess] = useState(false)

  const handleToken = async () => {
    try {
      const res = await TokenApiList.getToken()
      const { data } = res
      if (!data?.data?.token?.token_code || !data?.data?.token?.refresh_token) {
        throw new Error("Error Fetching token!", data)
      }
      const token = data.data.token.token_code
      const refreshToken = data.data.token.refresh_token
      Cookies.set("token", token, {
        expires: 1,
        sameSite: "None",
        secure: process.env.ENVIRONMENT !== "localhost",
      })
      Cookies.set("refresh_token", refreshToken, {
        expires: 1,
        sameSite: "None",
        secure: process.env.ENVIRONMENT !== "localhost",
      })
      // console.log("signedCookie run count: " + cookieCount)
      // cookieCount++
      let resSigned = await RootService.post(
        "api/fs/auth/generate-cookie",
        {},
        {
          headers: {
            ["standardized-token"]: token,
          },
          credentials: "include",
        }
      )

      const { data: genCookieData } = resSigned

      if (!genCookieData?.data?.encoded) {
        throw new Error("Error generating signed cookie!")
      }

      for (const [key, value] of Object.entries(genCookieData?.data?.encoded)) {
        Cookies.set(key, decodeURIComponent(value), {
          // 15 minute in milliseconds
          expires: 1,
          path: "/",
          domain: genCookieData?.data?.encoded?.domain,
          secure: process.env.ENVIRONMENT !== "localhost",
          httpOnly: process.env.ENVIRONMENT !== "localhost",
          sameSite: "None",
        })
      }

      postMessage("reload", process.env.NEXT_PUBLIC_ALLACCESS_URL)
      console.log("searchParams", searchParams.get("auth-data"))
      replace(`/allaccess-loading?auth-data=${searchParams.get("auth-data")}`)
      // handleURL(process.env.NEXT_PUBLIC_REDLAND_URL)
      // setIsPopupOpen(false)
    } catch (err) {
      console.log(err)
      setIsLoadingAccess(false)
    }
  }

  const requestStorageAccess = async () => {
    setIsLoadingAccess(true)
    if (document.hasStorageAccess) {
      try {
        await document.requestStorageAccess()
        console.log("Storage access granted")
        await handleToken()
      } catch (e) {
        console.log("Storage access denied")
        setIsLoadingAccess(false)
      }
    }
  }

  const { execute: login } = useReqUrl({
    redirectUrl: `${pathname}`,
  })

  const { execute: logout } = useLogout(pathname)

  const { execute: register } = useReqUrl({
    page: "register",
    redirectUrl: pathname,
  })

  function handlePoint(point) {
    if (point !== 0) {
      setPoint(point)
      handlePushQuery(false, [QUERYPARAM, true])
      setIsSuccess(true)
    }
  }

  async function handleSubmit(mission) {
    const { data: dataReturn } = await ClientApiList.postTrack({
      source: "AG",
      type: "whyNot",
      detail: mission,
    })
    handlePoint(dataReturn.data.data.point)
  }

  function handleDataLayer(data) {
    datalayer.push(data)
  }

  async function handleURL(url) {
    let newuUrl = url + `${isLogin ? "/allaccess-loading" : "/"}`
    // let newuUrl = url + `/allaccess-loading`
    setUrl(newuUrl)
    setIsLoadingAuth(false)
  }

  const handleSendMessage = () => {
    iframeRef.current.contentWindow.postMessage(
      {
        action: "cookie",
        data: {
          token: Cookies.get("token"),
          refresh_token: Cookies.get("refresh_token"),
        },
        engagement: searchParams.has("type")
          ? searchParams.get("type") === "engagement"
          : false,
      },
      "*"
    )
  }

  const removeHttps = (url) => {
    return url.replace(/^https:\/\//, "")
  }

  async function eventHandler(event) {
    if (event.data === "unmount") {
      Cookies.remove("token", {
        domain: removeHttps(process.env.NEXT_PUBLIC_META_URL),
        path: "/",
      })
      Cookies.remove("refresh_token", {
        domain: removeHttps(process.env.NEXT_PUBLIC_META_URL),
        path: "/",
      })
      Cookies.remove("CloudFront-Key-Pair-Id", {
        domain: ".marlboro.id",
        path: "/",
      })
      Cookies.remove("CloudFront-Policy", { domain: ".marlboro.id", path: "/" })
      Cookies.remove("CloudFront-Signature", {
        domain: ".marlboro.id",
        path: "/",
      })
      Cookies.remove("domain", { domain: ".marlboro.id", path: "/" })
    }

    const data = JSON.parse(atob(event.data))

    if (data.action == "datalayer") handleDataLayer(data.datalayer)
    else if (data.action == "backhome") {
      replace(`${process.env.NEXT_PUBLIC_META_URL}/br`)
    } else if (data.action === "login") {
      setIsShowSpinner(true)
      login()
    } else if (data.action === "register") {
      setIsShowSpinner(true)
      register()
    } else if (data.action === "getCookie") {
      handleSendMessage()
    } else if (data.action === "logout") {
      setIsShowSpinner(true)
      logout()
    } else if (data.action === "tracking") {
      if (data?.data?.action === "completed") handleSubmit(data?.data?.mission)
    }
  }

  const events = (e) => {
    if (
      e.origin !== process.env.NEXT_PUBLIC_REDLAND_URL &&
      e.origin !== process.env.NEXT_PUBLIC_ALLACCESS_URL
    )
      return
    eventHandler(e)
  }

  useEffect(() => {
    let url = `${process.env.NEXT_PUBLIC_REDLAND_URL}`

    if (Cookies.get("token") === undefined) {
      console.log("Cookies blocked, requesting access...")
      setIsPopupOpen(true)
    } else {
      handleURL(url)
    }

    window.addEventListener("message", events, false)

    return () => {
      if (iframeRef.current)
        iframeRef.current.contentWindow.postMessage(
          {
            action: "unmount",
          },
          "*"
        )
      window.removeEventListener("message", events, false)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading, isLogin])

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo berhasil dapetin ${point} poin!`

  if (isLoadingAuth || isLoadingAccess)
    return (
      <div className="h-[calc(100vh-170px)] flex items-center">
        {!isPopupOpen ? (
          <Spinner />
        ) : (
          <PopupAction
            zIndex="z-20"
            maxWidth={"max-w-[450px]"}
            padding="p-0"
            className={""}
            isShowing={isPopupOpen}
            href={"#"}
            onClickBtnX={() => {
              setIsPopupOpen(false)
            }}
            showCloseBtn={false}
          >
            <div
              className={cn(
                `relative z-[1] p-[20px] w-full overflow-hidden h-full rounded-[15px]`
              )}
            >
              <div className="text-[20px] lg:text-[24px] leading-[28px] lg:leading-[36px] font-bold font-font-family-3 mb-[10px] text-text-2">
                Action Needed
              </div>
              <Description
                className="text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px] lg:text-base font-medium font-font-family-6 text-text-2 mb-[20px] text-justify"
                description={
                  "<p>For the best experience, we kindly ask for your permission to enable cookies. If you haven’t visited the Marlboro website before, please click the ‘Visit Page’ button below. Once you’ve visited, you can return and click ‘Allow Cookies’ to continue. We truly appreciate your time and support!</p>"
                }
              />
              <div className="flex flex-col lg:flex-row items-center justify-between gap-[10px] max-w-[355px] lg:h-[50px] mx-auto mt-[20px]">
                <a
                  href={
                    !isLoadingAccess
                      ? `${process.env.NEXT_PUBLIC_META_URL}?utm_source=allaccess-iframe`
                      : "#"
                  }
                  target="_parent"
                  className={
                    isLoadingAccess
                      ? "pointer-events-none cursor-not-allowed inline-block w-[100%] grow-[1] lg:max-w-[172px]"
                      : "inline-block w-[100%] grow-[1] lg:max-w-[172px]"
                  }
                  aria-disabled={isLoadingAccess}
                  tabIndex={isLoadingAccess ? -1 : undefined}
                >
                  <ButtonAction
                    className="py-[15px] px-[20px] leading-[20px] font-font-family-7 w-[100%]"
                    intent={
                      !isLoadingAccess ? "secondary" : "secondary_disable"
                    }
                  >
                    Visit Page
                  </ButtonAction>
                </a>
                <ButtonAction
                  className="py-[15px] px-[20px] leading-[20px] font-font-family-7 grow-[1] lg:max-w-[172px]"
                  intent={!isLoadingAccess ? "primary" : "primary_disable"}
                  onClick={() => {
                    requestStorageAccess()
                  }}
                  disabled={isLoadingAccess}
                >
                  {isLoadingAccess ? "Loading..." : "Allow Cookie"}{" "}
                </ButtonAction>
              </div>
            </div>
          </PopupAction>
        )}
      </div>
    )

  return (
    <div className="w-[100%] h-[100%] max-w-[100dvw] h-[calc(100dvh-56px)] lg:h-[calc(100dvh-72px)] min-w-[100dvw]">
      <ToastComponent
        onClick={() => {
          setIsSuccess(false)
        }}
        title={titleBrand}
        desc="Lanjut eksplor dan kumpulkan lebih banyak poin!"
        isOpen={isSuccess}
      />
      {isShowSpinner ? (
        <div
          className={`fixed top-[0] backdrop-blur-[5px] bg-black/[.75] bottom-0 right-0 left-0 z-[19] flex items-center justify-center`}
        >
          {" "}
          <Spinner />
        </div>
      ) : null}
      <iframe
        ref={iframeRef}
        className="block w-[100dvw] h-[calc(100dvh-56px)]"
        seamless="seamless"
        title="game-view"
        src={url}
        frameBorder="0"
      ></iframe>
    </div>
  )
}