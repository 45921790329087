"use client"
import { CampaignAdditional } from "@/components/sections/campaign-additional"
import { CampaignChallenge } from "@/components/sections/campaign-challenge"
import React from "react"

export const Wrapper = ({ sections, ...props }) => {
  const isShort =
    sections.findIndex(
      (section) => section.component_name === "CampaignFinalChallenge"
    ) !== -1

  const handleRender = () => {
    return sections.map((section, index) => {
      if (section.component_name === "CampaignChallenge")
        return (
          <CampaignChallenge
            isShort={isShort}
            key={index}
            {...section}
            {...props}
          />
        )
      if (section.component_name === "CampaignFinalChallenge")
        return (
          <CampaignAdditional
            isSingle={sections.length === 1}
            key={index}
            {...section}
            {...props}
          />
        )
    })
  }

  return (
    <div
      id="mission-list"
      className="lg:flex lg:border-[1px] lg:p-[19px] lg:rounded-[15px] flex-row-reverse w-full col-span-2 gap-[15px]"
    >
      {handleRender()}
    </div>
  )
}
