import { useSearchParams } from "next/navigation"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useDatalayer } from "./use-datalayer"
import { ClientStorage } from "ClientStorage"
import { urlToCampaign } from "@/helper/utils"

const UseSoftgate = ({ key, pathname }) => {
  const localStorage = new ClientStorage("localStorage")
  const searchParams = useSearchParams()
  const [isOpen, setIsOpen] = useState(
    localStorage.get(key) === "true" && !pathname.includes("allaccess-loading")
  )

  const datalayer = useDatalayer({})

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      year: "",
      month: "",
    },
  })

  const [formError, setFormError] = useState(null)

  const reset = () => {
    setFormError(null)
  }

  useEffect(() => {
    const isSoftgate = localStorage.get(key) === "true"
    const redirectAllaccess =
      searchParams.get("to") === "allaccess" ||
      searchParams.get("to") === "allaccess-register" ||
      pathname.includes("allaccess-loading")

    searchParams && !isSoftgate && !redirectAllaccess ? open() : close()
  }, [key, searchParams])

  const onSubmit = (data) => {
    setFormError(null)

    const { year, month } = data

    const age = getPersonAge(year, month)

    if (age >= 21) {
      localStorage.set(key, "true")
      close()
      datalayer.push({
        event: "general_event",
        event_name: `success_age_gate`, // success_age_gate  or  failed_age_gate
        feature: "general",
        campaign_name:
          String(pathname).match(/^\/[\w-.]+\/[br|ub]{2}/g) ||
          String(pathname).match(/^\/[br|ub]{2}\/[\w-.]+/g)
            ? String(pathname).match(/^\/[\w-.]+\/[br|ub]{2}/g)
              ? urlToCampaign(pathname)
              : urlToCampaign(pathname, 2)
            : "all",
      })
    } else {
      datalayer.push({
        event: "general_event",
        event_name: `failed_age_gate`, // success_age_gate  or  failed_age_gate
        feature: "general",
        campaign_name: "all",
      })
      setFormError(
        "Sesuai peraturan yang berlaku, website ini hanya bisa diakses oleh pengunjung yang berumur 21 tahun keatas."
      )
    }
  }

  const yearOptions = { required: true, min: 1940 }
  const monthOptions = { required: true, min: 1 }
  return {
    isOpen,
    open,
    close,
    register,
    getValues,
    errors,
    formError,
    watch,
    reset,
    monthOptions,
    yearOptions,
    onSubmit: handleSubmit(onSubmit),
  }
}

export default UseSoftgate

// calculate person age from year and month
const getPersonAge = (year, month) => {
  const today = new Date()
  const currentYear = today.getFullYear()
  const currentMonth = today.getMonth()

  const age = currentYear - year

  if (currentMonth < month) {
    return age - 1
  }

  return age
}
