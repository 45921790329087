"use client"
import React, { useEffect, useState } from "react"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import PropTypes from "prop-types"
import { getArticlePathname } from "@/hooks/use-pathname"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname } from "next/navigation"
import { ClientApiList } from "@/lib/api/client-service"
import UseQueryParams from "@/hooks/use-query-params"
import _ from "lodash"

export default function MissionCard({
  handleDataLayer,
  item,
  brand_preference_popup,
  prevId,
  ...props
}) {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const [isOnHold, setIsOnHold] = useState(false)
  const { handleManipulateQuery, handleFilterQuery, handlePushQuery } =
    UseQueryParams()
  const url = getArticlePathname({
    ...item,
    format: item?.format || item?.engagement_type,
  })

  const {
    page_uuid,
    is_locked,
    point_get,
    mobile_image_url,
    desktop_image_url,
    is_completed,
    format,
    string_id,
    cat_string_id,
    start_date,
    order,
  } = item

  const imgCover = isMobile ? mobile_image_url : desktop_image_url

  const thumbnailInjectStyleFont = () => {
    if (is_locked) {
      return "var(--text_4)"
    } else if (is_completed) {
      return "var(--text_3)"
    } else {
      return "var(--text_4)"
    }
  }

  const thumbnailInjectStylePill = () => {
    if (is_locked) {
      return "var(--text_2)"
    } else if (is_completed) {
      return "var(--cta_4)"
    } else {
      return "var(--text_1)"
    }
  }

  const thumbnailStatusClass = () => {
    if (is_locked) {
      return "text-text-4 bg-bg-2"
    } else if (is_completed) {
      return "bg-cta-4 text-text-5"
    } else {
      return "bg-text-1 text-text-4"
    }
  }

  const renderStatus = () => {
    if (is_locked) {
      return "Locked"
    } else if (is_completed) {
      return "Finished"
    } else {
      return "New!"
    }
  }

  const renderIcon = () => {
    if (is_locked) {
      return <Padlock />
    } else {
      return <TickCircle />
    }
  }

  const handleGetNextUrl = async () => {
    try {
      const { data: nextUrlData } = await ClientApiList.getNextChallenge({
        page_uuid: prevId.page_uuid,
        mission_order: order,
      })

      if (nextUrlData.data.is_show_popup) {
        setIsOnHold(nextUrlData.data.is_show_popup)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    handleGetNextUrl()
  }, [])

  return (
    <Link
      data-testid="mission-card-link"
      className={is_locked ? "pointer-events-none cursor-not-allowed" : ""}
      aria-disabled={is_locked}
      tabIndex={is_locked ? -1 : undefined}
      href={
        is_locked
          ? "#"
          : brand_preference_popup === "active"&& item.pos !== 0 && isOnHold
            ? `${pathname}?holdstep=brandpref&link=${encodeURIComponent(`${url}?page_uuid=${page_uuid}&index=${order}&campaign=${pathname}`)}`
            : `${url}?page_uuid=${page_uuid}&index=${order}&campaign=${pathname}`
      }
      scroll={false}
      onClick={() => {
        handleDataLayer(format, cat_string_id, order, string_id, start_date)
      }}
    >
      <div className="p-[10px] bg-bg-3 text-text-2 h-[204px] w-[100%] max-w-[185px] rounded-[10px] z-[10] overflow-hidden">
        <div className="flex items-center justify-between mb-[10px] gap-[5px]">
          <div
            data-testid="mission-title"
            className="shrink-[70px] whitespace-nowrap text-ellipsis overflow-hidden capitalize text-text-2 text-[12px] leading-[16px] tracking-[0.5px] font-bold font-font-family-7 "
          >
            {string_id.replaceAll("-", " ")}
          </div>
          <div
            style={{ background: thumbnailInjectStylePill }}
            className={`${thumbnailStatusClass()} shrink-[0] max-h-[25px] !w-fit max-w-[79px] flex items-center gap-[5px] p-[5px] text-[12px] uppercase leading-[15px] tracking-[0.25px] right-[10px] top-[10px] rounded-[10px] `}
          >
            {is_completed || is_locked ? (
              <div className="!w-[15px] !h-[15px]">{renderIcon()}</div>
            ) : null}
            <div
              style={{
                background: thumbnailInjectStyleFont(),
                WebkitTextFillColor: "transparent",
              }}
              data-testid="mission-status"
              className="overflow-visible whitespace-nowrap !bg-clip-text"
            >
              {renderStatus()}
            </div>
          </div>
        </div>
        <div className="rounded-[10px] overflow-hidden w-[165px] h-[123px] relative">
          {is_locked ? (
            <div className="absolute inset-0 z-[1] bg-black/[0.5] flex items-center justify-center">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="vuesax/bold/lock">
                  <path
                    id="Vector"
                    d="M19.9979 28.9164C21.4983 28.9164 22.7146 27.7001 22.7146 26.1997C22.7146 24.6993 21.4983 23.483 19.9979 23.483C18.4975 23.483 17.2812 24.6993 17.2812 26.1997C17.2812 27.7001 18.4975 28.9164 19.9979 28.9164Z"
                    fill="white"
                  />
                  <path
                    id="Vector_2"
                    d="M30.4693 15.8834V13.8C30.4693 9.30004 29.3859 3.33337 20.0026 3.33337C10.6193 3.33337 9.53594 9.30004 9.53594 13.8V15.8834C4.86927 16.4667 3.33594 18.8334 3.33594 24.65V27.75C3.33594 34.5834 5.41927 36.6667 12.2526 36.6667H27.7526C34.5859 36.6667 36.6693 34.5834 36.6693 27.75V24.65C36.6693 18.8334 35.1359 16.4667 30.4693 15.8834ZM20.0026 31.2334C17.2193 31.2334 14.9693 28.9667 14.9693 26.2C14.9693 23.4167 17.2359 21.1667 20.0026 21.1667C22.7693 21.1667 25.0359 23.4334 25.0359 26.2C25.0359 28.9834 22.7859 31.2334 20.0026 31.2334ZM12.2526 15.7334C12.1193 15.7334 12.0026 15.7334 11.8693 15.7334V13.8C11.8693 8.91671 13.2526 5.66671 20.0026 5.66671C26.7526 5.66671 28.1359 8.91671 28.1359 13.8V15.75C28.0026 15.75 27.8859 15.75 27.7526 15.75H12.2526V15.7334Z"
                    fill="white"
                  />
                </g>
              </svg>
            </div>
          ) : null}
          <Image
            className="absolute inset-0 w-[100vw]"
            alt="cover"
            data-testid="mission-image"
            style={{ objectFit: "cover", objectPosition: "center" }}
            src={imgCover}
          />
        </div>
        {point_get > 0 ? (
          <div className="mt-[8px] flex items-center gap-[5px] text-[14px] leading-[16px] text-text-1">
            <Image
              className="!h-[18px] !w-[18px]"
              src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
              alt="reward"
            />
            {point_get} Poin
          </div>
        ) : null}
      </div>
    </Link>
  )
}

MissionCard.propTypes = {
  handleDataLayer: PropTypes.func,
  pos: PropTypes.number,
  item: PropTypes.object,
}

const TickCircle = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/tick-circle">
        <g id="tick-circle">
          <path
            id="Vector"
            d="M7.5 13.75C10.9375 13.75 13.75 10.9375 13.75 7.5C13.75 4.0625 10.9375 1.25 7.5 1.25C4.0625 1.25 1.25 4.0625 1.25 7.5C1.25 10.9375 4.0625 13.75 7.5 13.75Z"
            stroke="#969594"
            strokeWidth="1.40625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M4.84375 7.49922L6.6125 9.26797L10.1562 5.73047"
            stroke="#969594"
            strokeWidth="1.40625"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  )
}

const Padlock = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49922 10.8441C8.06186 10.8441 8.51797 10.388 8.51797 9.82539C8.51797 9.26275 8.06186 8.80664 7.49922 8.80664C6.93658 8.80664 6.48047 9.26275 6.48047 9.82539C6.48047 10.388 6.93658 10.8441 7.49922 10.8441Z"
        className="fill-icon-4"
      />
      <path
        d="M11.425 5.95625V5.175C11.425 3.4875 11.0188 1.25 7.5 1.25C3.98125 1.25 3.575 3.4875 3.575 5.175V5.95625C1.825 6.175 1.25 7.0625 1.25 9.24375V10.4062C1.25 12.9688 2.03125 13.75 4.59375 13.75H10.4062C12.9688 13.75 13.75 12.9688 13.75 10.4062V9.24375C13.75 7.0625 13.175 6.175 11.425 5.95625ZM7.5 11.7125C6.45625 11.7125 5.6125 10.8625 5.6125 9.825C5.6125 8.78125 6.4625 7.9375 7.5 7.9375C8.5375 7.9375 9.3875 8.7875 9.3875 9.825C9.3875 10.8688 8.54375 11.7125 7.5 11.7125ZM4.59375 5.9C4.54375 5.9 4.5 5.9 4.45 5.9V5.175C4.45 3.34375 4.96875 2.125 7.5 2.125C10.0312 2.125 10.55 3.34375 10.55 5.175V5.90625C10.5 5.90625 10.4563 5.90625 10.4062 5.90625H4.59375V5.9Z"
        className="fill-icon-4"
      />
    </svg>
  )
}
