"use client"
import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { Image } from "@/components/shared/image/image"
import ProgressBar from "../shared/progress-bar/progress-bar"
import PopupAction from "../shared/popup-action/popup-action"
import ButtonAction from "../shared/button-action/button-action"
import PropTypes from "prop-types"
import { useDatalayer } from "@/hooks/use-datalayer"
import { ClientApiList } from "@/lib/api/client-service"
import { Spinner } from "../shared/spinner"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useWindowSize } from "@/hooks/use-window-size"
import { getArticlePathname } from "@/hooks/use-pathname"
import PopupNextQuestion from "./popup-next-question/popup-next-question"
import PopupCloseConfirmQuiz from "./popup-close-confirm-quiz/popup-close-confirm-quiz"
import UseQueryParams from "@/hooks/use-query-params"
import { twMerge } from "tailwind-merge"
import { useIsMobile, useSmallHeight } from "@/hooks/use-is-mobile"
import { ToastComponent } from "../toast"
import { postMessage, snackbarPoint, urlAsset } from "@/helper/utils"
import QuizAnswer from "./quiz-answer"
import QuizMultipleChoice from "./quiz-multiple-choice"
import QuizTextAnswer from "./quiz-text-answer"
import QuizRange from "./quiz-range"
import { cn } from "@/lib/utils"

export const GameQuiz = (props) => {
  const { stage, setStage, quiz, game, images, detail } = props
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const pathname = usePathname()
  const [nextUrl, setNextUrl] = useState("/br")
  const isShowing = true
  const [success, setSuccess] = useState(false)
  const [point, setPoint] = useState(false)
  const [number, setNumber] = useState(0)
  const [answer, setAnswer] = useState(0)
  const [multipleAnswer, setMultipleAnswer] = useState([])
  const [textAnswer, setTextAnswer] = useState("")
  const [rangeAnswer, setRangeAnswer] = useState("0")
  const [loading, setLoading] = useState(false)
  const [titlePopup, setTitlePopup] = useState("")
  const [subPopup, setSubPopup] = useState("")
  const [imgPopup, setImgPopup] = useState("")
  const [resultType, setResultType] = useState("")
  const [successSend, setSuccessSend] = useState(false)
  const [isExited, setIsExited] = useState(false)
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [isOnHold, setIsOnHold] = useState(false)
  const MAX_CHAR = 1000
  const datalayer = useDatalayer()

  const handleDatalayer = (datalayerData) => {

    if (quiz?.is_special_engagement) {
      postMessage(
        { action: "datalayer", datalayer:datalayerData },
        process.env.NEXT_PUBLIC_META_URL
      )
    } else {
      datalayer.push(datalayerData)
    }
  }

  const isFormalYou = process.env.NEXT_PUBLIC_NAME === "djisamsoe"

  const handleActionBtnBg = () => {
    if (process.env.NEXT_PUBLIC_NAME === "sampoernakretek" || process.env.NEXT_PUBLIC_NAME === "marlboro")
      return "var(--background_1)"
    else return "var(--cta_2)"
  }

  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const searchParams = useSearchParams()
  const isSmallHeight = useSmallHeight()
  const isMobile = useIsMobile()
  const { replace } = useRouter()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [number])

  const ALPHABETH = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
  }

  const handleReset = () => {
    setAnswer(0)
    setMultipleAnswer([])
    setTextAnswer("")
    setRangeAnswer("0")
  }

  const SubmitAnswer = async () => {
    setLoading(true)
    let answerToSubmit
    let questionOpt = game[number]?.question_opt

    if (questionOpt === "checkbox")
      answerToSubmit = multipleAnswer
        .map((choice) => ALPHABETH[choice])
        .join(",")
    else if (questionOpt === "multiple") answerToSubmit = ALPHABETH[answer]
    else if (questionOpt === "rating") answerToSubmit = rangeAnswer
    else answerToSubmit = textAnswer

    const { data } = await ClientApiList.getQuizAnswer({
      engagement_uuid: quiz.engagement_uuid,
      answer: answerToSubmit,
      order: number + 1,
      question_opt: questionOpt,
    })

    if (
      game?.length !== number + 1 &&
      detail.data.data.quiz.point_setup === "specific"
    ) {
      handleDatalayer({
        event: "general_event",
        event_name: "click_kirim_jawaban_quiz",
        feature: "simple engagement",
        engagement_type: "games",
        engagement_name: "quiz",
        campaign_name: quiz?.is_special_engagement
          ? "why not 2025"
          : quiz?.campaign_name.toLowerCase(), // e.g all
        event_label: `quiz - ${quiz?.subtitle_introduction.toLowerCase()}`,
        level: number + 1,
        content_id: quiz.engagement_uuid,
      })

      setPoint(data?.data?.result?.data?.point || 0)
      setTitlePopup(
        data?.data?.result?.data?.result_info?.result_quiz ||
          `${isFormalYou ? "Kamu" : "Lo"} Berhasil Dapet`
      )
      setSubPopup(
        data?.data?.result?.data?.result_info?.result_desc ||
          `Jawaban ${isFormalYou ? "kamu" : "lo"} berhasil di kirim! Jawab pertanyaan selanjutnya buat dapat lebih banyak poin!`
      )
      setImgPopup(
        data?.data?.result?.data?.result_info
          ? isDesktop
            ? data.data.result.data.result_info.result_desktop_image_file_url
            : data.data.result.data.result_info.result_mobile_image_file_url
          : `${urlAsset()}/puzzle/levelup.png`
      )
      setSuccessSend(true)
      setNumber(number + 1)
      handleReset()
      setLoading(false)
    } else {
      if (game?.length === number + 1) {
        handleDatalayer({
          event: "general_event",
          event_name: "click_kirim_jawaban_quiz",
          feature: "simple engagement",
          engagement_type: "games",
          engagement_name: "quiz",
          campaign_name: quiz?.is_special_engagement
            ? "why not 2025"
            : quiz?.campaign_name.toLowerCase(), // e.g all
          event_label: `quiz - ${quiz?.subtitle_introduction.toLowerCase()}`,
          level: number + 1,
          content_id: quiz.engagement_uuid,
        })

        if (data) {
          const result_point = data?.data?.result?.data?.point
          setResultType(data.data.result.data.result_type)
          setSuccess(true)
          setPoint(result_point)
          setTitlePopup(data.data.result.data.result_info.result_quiz)
          setSubPopup(data.data.result.data.result_info.result_desc)
          setImgPopup(
            isDesktop
              ? data.data.result.data.result_info.result_desktop_image_file_url
              : data.data.result.data.result_info.result_mobile_image_file_url
          )
          setLoading(false)

          handleDatalayer({
            event: "general_event",
            event_name: "page_reached_finish_on_quiz",
            feature: "simple engagement",
            engagement_type: "games",
            engagement_name: "quiz",
            level: number + 1,
            campaign_name: quiz?.is_special_engagement
              ? "why not 2025"
              : quiz?.campaign_name.toLowerCase(), // e.g all
            event_label: `quiz - ${quiz?.subtitle_introduction.toLowerCase()}`,
            character_result:
              data.data.result.data.result_info?.result_quiz?.toLowerCase(),
            content_id: quiz.engagement_uuid,
          })

          if (quiz?.is_special_engagement)
            postMessage({ activityId: quiz?.activity_id })

          if (searchParams.get("page_uuid")) {
            const { data: nextUrlData } = await ClientApiList.getNextChallenge({
              page_uuid: searchParams.get("page_uuid"),
              mission_order: parseInt(searchParams.get("index")),
            })

            if (
              Object.keys(nextUrlData.data.data).length === 0 &&
              nextUrlData.data.data.constructor === Object
            ) {
              setNextUrl(searchParams.get("campaign"))
            } else {
              console.log("here")
              if (nextUrlData.data.is_show_popup) setIsOnHold(nextUrlData.data.is_show_popup)
              // localStorage.removeItem("brand-preference")
              // setTimeout(() => {
              //   handlePushQuery(false, ["brandPreferences", true])
              // }, [200])
              // } else {
              const nextMissionUrl = getArticlePathname({
                ...nextUrlData.data.data,
              })
              setNextUrl(
                `${nextMissionUrl}?page_uuid=${searchParams.get("page_uuid")}&index=${parseInt(searchParams.get("index")) + 1}&campaign=${searchParams.get("campaign")}${result_point ? `&${QUERYPARAM}=true` : ""}`
              )
              // }
            }
          }
          if (result_point) {
            handlePushQuery(false, [QUERYPARAM, true])
            setShowSnackbar(true)
          }
        }
      } else {
        handleDatalayer({
          event: "general_event",
          event_name: "click_kirim_jawaban_quiz",
          feature: "simple engagement",
          engagement_type: "games",
          engagement_name: "quiz",
          campaign_name: quiz?.is_special_engagement
            ? "why not 2025"
            : quiz?.campaign_name.toLowerCase(), // e.g all
          event_label: `quiz - ${quiz?.subtitle_introduction.toLowerCase()}`,
          level: number + 1,
          content_id: quiz.engagement_uuid,
        })
        setNumber(number + 1)
        handleReset()
        setLoading(false)
      }
    }
  }

  const handleBGNotChosen = () => {
    return "rgba(128,128,128,0.75)"
  }

  const handleBG = (idx) => {
    switch (process.env.NEXT_PUBLIC_NAME) {
      case "djisamsoe":
        return answer > 0 && answer !== idx + 1
          ? "var(--icon_3)"
          : "linear-gradient(180deg, #F7E086 0%, #DF730F 100%)"
      case "magnum":
        return answer > 0 && answer !== idx + 1
          ? "var(--icon_3)"
          : "linear-gradient(98.87deg, #A47E24 0%, #6B5217 100%)"
      case "sampoernakretek":
        return answer > 0 && answer !== idx + 1
          ? "var(--icon_3)"
          : "linear-gradient(180deg, #C7D943 0%, #89A627 100%)"
      default:
        return answer > 0 && answer !== idx + 1
          ? "var(--icon_3)"
          : "linear-gradient(304deg, #FF4D53 7.51%, #ED1C24 50.19%, #DB0C14 90.63%)"
    }
  }

  const handleBorder = (idx) => {
    switch (process.env.NEXT_PUBLIC_NAME) {
      case "magnum":
        return answer > 0 && answer !== idx + 1
          ? ""
          : "linear-gradient(98.87deg, #A47E24 0%, #6B5217 100%)"
      default:
        return answer > 0 && answer !== idx + 1 ? "" : "var(--cta_1)"
    }
  }

  const handleBorderMultiple = (idx) => {
    const foundAnswer = multipleAnswer.find(
      (answerIdx) => answerIdx === idx + 1
    )

    switch (process.env.NEXT_PUBLIC_NAME) {
      case "magnum":
        return multipleAnswer.length > 0 && !foundAnswer
          ? ""
          : multipleAnswer.length === 0
            ? ""
            : "linear-gradient(98.87deg, #A47E24 0%, #6B5217 100%)"
      default:
        return multipleAnswer.length > 0 && !foundAnswer
          ? ""
          : multipleAnswer.length === 0
            ? ""
            : "var(--cta_1)"
    }
  }

  const handleInstruction = () => {
    switch (game[number]?.question_opt) {
      // case "multiple":
      //   return `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} cuma bisa pilih satu jawaban`
      case "checkbox":
        return `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu" : "Lo"} bisa pilih lebih dari satu jawaban`
      case "short_text":
        return `Tulis jawaban ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"}  di kotak bawah`
      case "rating":
        return `Beri rating ${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "kamu" : "lo"} di bawah ini`
      default:
        return ""
    }
  }

  return (
    <>
      <Image
        alt="cover"
        fill
        style={{ objectFit: "cover" }}
        src={
          !isDesktop
            ? props?.detail?.data?.data?.quiz?.mobile_image_file
            : props?.detail?.data?.data?.quiz?.desktop_image_file
        }
        className="z-[0] absolute inset-0 w-[100%]"
      />
      {stage !== 1 && (
        <article
          className={clsx(
            "relative w-full min-h-[715px]",
            isDesktop ? "pt-[13px]" : "px-[15px]"
          )}
        >
          <button
            onClick={() => setStage(1)}
            style={{
              background: handleActionBtnBg(),
              border: "1px solid var(--cta_4)",
            }}
            className={twMerge(
              "absolute z-[3] top-[-10px] left-[6px] min-h-[50px] py-[12px] px-[15px] rounded-[10px] border-[1px] lg:flex items-center lg:gap-[5px] lg:p-[11px] lg:left-[30px] lg:top-[30px]"
            )}
            {...props}
          >
            <IconBack />
            <div
              className={`hidden lg:block font-bold font-font-family-7 text-[14px] text-text-2`}
            >
              Kembali
            </div>
          </button>
          <button
            onClick={() => setIsExited(true)}
            style={{
              background: handleActionBtnBg(),
              border: "1px solid var(--cta_4)",
            }}
            className={twMerge(
              "absolute z-[3] top-[-10px] right-[6px] min-h-[50px] py-[12px] px-[15px] rounded-[10px] border-[1px] lg:flex items-center lg:gap-[5px] lg:p-[11px] lg:right-[30px] lg:top-[30px]"
            )}
            {...props}
          >
            {isDesktop && <span className="text-text-2">Tutup</span>}
            <IconClose />
          </button>
          <div className="text-text-2 w-full max-w-[460px] mx-auto">
            <div className="w-full flex justify-center items-center">
              <h2 className="text-xl font-medium font-font-family-6 text-center max-w-[250px]">
                {quiz.subtitle_introduction}
              </h2>
            </div>

            <ProgressBar
              className={clsx(
                "mt-[30px] mb-[5px]",
                isDesktop ? "h-[10px] rounded-[100px]" : "h-[7px]"
              )}
              current={number}
              total={game?.length}
            />
            <div>
              <span
                className={clsx(
                  "text-text-1 font-bold font-font-family-7",
                  isDesktop ? "text-base" : "text-sm"
                )}
              >
                {number}
              </span>
              <span
                className={clsx(
                  "text-text-2 leading-[0.25px] font-bold font-font-family-7",
                  isDesktop ? "text-base" : "text-sm"
                )}
              >
                /{game?.length}{" "}
              </span>
              <span
                className={clsx(
                  "leading-[0.25px] font-medium font-font-family-6",
                  isDesktop ? "text-sm" : "text-xs"
                )}
              >
                Pertanyaan Terjawab
              </span>
            </div>
            <div className="mt-[20px]">
              <p
                data-testid="quiz-question"
                className={cn(
                  "text-xl font-bold font-font-family-7",
                  "mb-[5px] lg:mb-[10px]"
                )}
              >
                {game[number]?.question}
              </p>
              <p className="text-[14px] lg:text-[16px] leading-[20px] lg:leading-[24px]">
                {handleInstruction()}
              </p>
              {/* Quiz with one Asnwer */}
              {game[number]?.question_opt === "multiple" && (
                <QuizAnswer
                  answer={answer}
                  game={game}
                  handleBG={handleBG}
                  handleBGNotChosen={handleBGNotChosen}
                  handleBorder={handleBorder}
                  images={images}
                  number={number}
                  setAnswer={setAnswer}
                  multipleAnswer={multipleAnswer}
                  setMultipleAnswer={setMultipleAnswer}
                />
              )}
              {/* for quiz with multiple answer */}
              {game[number]?.question_opt === "checkbox" && (
                <QuizMultipleChoice
                  answer={multipleAnswer}
                  game={game}
                  // handleBG={handleBG}
                  // handleBGNotChosen={handleBGNotChosen}
                  handleBorder={handleBorderMultiple}
                  images={images}
                  number={number}
                  setAnswer={setMultipleAnswer}
                />
              )}
              {/* Text Answer */}
              {game[number]?.question_opt === "short_text" && (
                <QuizTextAnswer
                  maxChar={MAX_CHAR}
                  answer={textAnswer}
                  setAnswer={setTextAnswer}
                />
              )}
              {/* Range Answer */}
              {game[number]?.question_opt === "rating" && (
                <QuizRange
                  labels={{
                    min: game[number]?.label_min_rating,
                    max: game[number]?.label_max_rating,
                  }}
                  answer={rangeAnswer}
                  setAnswer={setRangeAnswer}
                  maxRating={game[number]?.max_rating}
                />
              )}

              {loading ? (
                <div className="max-w-[345px] mt-[30px] mx-auto flex justify-center">
                  <Spinner />
                </div>
              ) : (
                <div
                  className={clsx(
                    "max-w-[345px] mx-auto",
                    isDesktop ? "mt-[30px]" : "mt-[20px]"
                  )}
                >
                  {(answer > 0 ||
                    number > 0 ||
                    multipleAnswer.length > 0 ||
                    textAnswer ||
                    game[number]?.question_opt === "rating") && (
                    <ButtonAction
                      intent={
                        answer > 0 ||
                        multipleAnswer.length > 0 ||
                        textAnswer ||
                        game[number]?.question_opt === "rating"
                          ? "primary"
                          : "primary_disable"
                      }
                      onClick={() => SubmitAnswer()}
                      className={"w-full"}
                      disabled={
                        answer === 0 &&
                        multipleAnswer.length === 0 &&
                        !textAnswer &&
                        game[number]?.question_opt !== "rating"
                      }
                    >
                      {game?.length === number + 1
                        ? "Kirim Jawaban"
                        : "Selanjutnya"}
                    </ButtonAction>
                  )}
                  {number > 0 && (
                    <ButtonAction
                      intent="secondary"
                      onClick={() => {
                        setNumber(number - 1)
                        handleReset()
                      }}
                      className="w-full mt-[10px]"
                    >
                      Sebelumnya
                    </ButtonAction>
                  )}
                </div>
              )}
            </div>
          </div>
          <PopupNextQuestion
            isShowing={successSend}
            titlePopup={titlePopup}
            point={point}
            handleExit={setIsExited}
            handleSuccesSend={setSuccessSend}
            imgPopup={imgPopup}
            subPopup={subPopup}
          />
          <PopupCloseConfirmQuiz
            isFormalYou={isFormalYou}
            isShowing={isExited}
            titlePopup={"Yakin mau keluar? "}
            handleExit={setIsExited}
            imgPopup={`/assets/${process.env.NEXT_PUBLIC_NAME}/mission/popup-confirm-exit.png`}
          />
          {success && (
            <PopupAction
              isShowing={isShowing}
              href={nextUrl || searchParams.has("stop") ? "#" : "/"}
              className={resultType === "full_image" ? "p-0" : "!p-[10px]"}
              zIndex="z-[20]"
              isFullImage={resultType === "full_image"}
              showCloseBtn={nextUrl || searchParams.has("stop")}
              onClickBtnX={() => {
                setSuccess(false)
              }}
            >
              {resultType === "full_image" ? (
                <>
                  <Image
                    alt="banner"
                    src={imgPopup}
                    className={"max-h-[70dvh]"}
                    objectFit="contain"
                  />
                  <ToastComponent
                    onClick={() => {
                      setShowSnackbar(false)
                    }}
                    title={snackbarPoint(point)?.title}
                    desc={snackbarPoint()?.subtitle}
                    isOpen={showSnackbar}
                  />
                </>
              ) : (
                <div className="w-full h-full p-[15px] rounded-[10px] border-[2px] border-cta-3 border-dashed">
                  <div
                    className={clsx(
                      "h-full relative aspect-[304/177] bg-cta-1 mx-auto",
                      isSmallHeight && "w-[200px]",
                      isMobile && !isSmallHeight && "w-[250px]"
                    )}
                  >
                    <Image alt="banner" objectFit="cover" src={imgPopup} />
                  </div>
                  <div className="my-[20px] max-w-[304px]">
                    <p className="text-xl font-black font-font-family-8 mb-[5px] text-text-2 capitalize">
                      {titlePopup}
                    </p>
                    {point > 0 && (
                      <div className="flex items-center gap-[5px] justify-center mb-[5px]">
                        <div className="min-h-[20px] w-fit min-w-[20px] relative">
                          <Image
                            alt="cover"
                            fill
                            style={{
                              objectFit: "cover",
                              objectPosition: "center",
                              position: "absolute",
                              inset: 0,
                            }}
                            src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
                          />
                        </div>
                        <div className="text-[20px] text-text-1 leading-[27px] font-black font-font-family-8">
                          {point} Poin!
                        </div>
                      </div>
                    )}
                    <p
                      className={clsx(
                        "text-center font-medium font-font-family-6 text-text-2",
                        isDesktop ? "text-base" : "text-sm"
                      )}
                    >
                      {subPopup}
                    </p>
                  </div>
                  {nextUrl ? (
                    <ButtonAction
                      onClick={() => {
                        isOnHold
                          ? replace(pathname+`?holdstep=brandpref&link=${encodeURIComponent(nextUrl)}`)
                          : replace(nextUrl)
                      }}
                      className="py-[15px] px-[20px] mb-[10px]"
                      intent="primary"
                    >
                      {"Eksplor Tantangan Selanjutnya"}
                    </ButtonAction>
                  ) : null}
                  <ButtonAction
                    data-testid="popup-cancel"
                    onClick={() => replace("/br")}
                    className="py-[15px] px-[20px]"
                    intent="secondary"
                  >
                    {"Cek keseruan lainnya"}
                  </ButtonAction>
                </div>
              )}
            </PopupAction>
          )}
        </article>
      )}
    </>
  )
}

GameQuiz.propTypes = {
  stage: PropTypes.number,
  setStage: PropTypes.number,
  game: PropTypes.array,
  quiz: PropTypes.object,
}

const IconClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.83594 5.83301L14.1693 14.1663M5.83594 14.1663L14.1693 5.83301"
        // stroke="#2C2A29"
        className="stroke-icon-2"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const IconBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.9999 11H6.41394L11.7069 5.707L10.2929 4.293L2.58594 12L10.2929 19.707L11.7069 18.293L6.41394 13H20.9999V11Z"
        className="fill-icon-2"
      />
    </svg>
  )
}

GameQuiz.propTypes = {
  stage: PropTypes.number.isRequired,
  setStage: PropTypes.number.isRequired,
  game: PropTypes.array.isRequired,
  detail: PropTypes.object,
  images: PropTypes.array,
}
