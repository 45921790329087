"use client"
import { Image } from "@/components/shared/image/image"
import Link from "@/components/shared/link/link"
import React from "react"
import PropTypes from "prop-types"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useReqUrl } from "@/hooks/auth/use-req-url"

export default function NavImage({ href, icon, label }) {
  const datalayer = useDatalayer()

  const { execute: login } = useReqUrl({
    redirectUrl: href,
  })

  const onClickDatalayer = () => {
    datalayer.push({
      event: `general_event`,
      event_name: `click_dynamic_navigation`,
      feature: "customized navigation",
      button_name: label?.toLowerCase(),
      campaign_name: href.split("/").filter(Boolean)[1].split("-").join(" "),
      engagement_type: "interaction",
    })
  }
  return (
    <Link
      data-testid="nav-image-link"
      className="block grow-0 shrink-0 basis-[65px] max-w-[75px] text-text-3"
      href={String(label).toLowerCase() === "riders zone" ? "#" : href}
      passHref
      onClick={() => {
        String(label).toLowerCase() === "riders zone" && login()
        onClickDatalayer()
      }}
    >
      <div className={`relative min-w-[65px] min-h-[65px]`}>
        <div
          className={`absolute h-[80px] w-[65px] bottom-[14px] translate-x-[-50%] left-[50%]`}
        >
          <Image data-testid="nav-image-icon" src={icon} alt={label} />
        </div>
      </div>
    </Link>
  )
}

NavImage.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
}
