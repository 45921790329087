import React, { useState } from "react"
import UpvoteButton from "../upvote-button/upvote-button"
import clsx from "clsx"
import Separator from "@/components/slide-menu/separator/separator"
import { TopVoted } from "@/components/sections/gallery-submission"
import { Image } from "@/components/shared/image/image"
import { ClientApiList } from "@/lib/api/client-service"
import { IconPlay } from "../components/submission-icons"
import PropTypes from "prop-types"

const MusicCard = ({
  vote = true,
  item,
  dataAudio,
  setSelectedAudio,
  setDataMusic,
  setIsPreviewOpen,
  vote_copy_text
}) => {
  const isBrandSAH = process.env.NEXT_PUBLIC_NAME === "sampoernakretek"
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const [onLoad, setOnLoad] = useState(false)

  /* posted date */
  function daysBetween(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const diffDays = Math.round(Math.abs((startDate - endDate) / oneDay))
    return diffDays
  }

  /* Target date (change this to your desired date) */
  const targetDate = new Date(item?.approval_date)

  /* Calculate days since target date*/
  const currentDate = new Date()
  const days = daysBetween(currentDate, targetDate)

  const bgBtn = () => {
    if (vote) return "var(--cta_3)"
    else return isBrandSAH ? "var(--background_1)" : "var(--cta_2)"
  }

  const onVote = async () => {
    setOnLoad(true)
    try {
      const { data } = await ClientApiList.getGalleryVote({
        vote: vote === 0 ? "vote" : "unvote",
        submission_uuid: item?.uuid,
        type: "audio",
      })
      if (data) {
        setOnLoad(false)
        setDataMusic(
          dataAudio.map((audio) => {
            if (audio.uuid === item?.uuid) {
              setSelectedAudio({
                ...audio,
                voted: vote === 0 ? 1 : 0,
                total_vote:
                  vote === 0
                    ? parseInt(item?.total_vote) + 1 || 0 + 1
                    : item?.total_vote - 1,
              })
              return {
                ...audio,
                voted: vote === 0 ? 1 : 0,
                total_vote:
                  vote === 0
                    ? parseInt(item?.total_vote) + 1 || 0 + 1
                    : item?.total_vote - 1,
              }
            }
            return audio
          })
        )
      }
    } catch (err) {
      setOnLoad(false)
    }
  }

  return (
    <div
      className="w-full min-h-[340px] flex flex-col items-center justify-end relative cursor-pointer"
      onClick={(e) => {
        setSelectedAudio(item)
        setTimeout(() => {
          setIsPreviewOpen(true)
        }, 600)
        e.stopPropagation()
      }}
    >
      <div className="absolute inset-0 z-[1] bg-[linear-gradient(180deg,_rgba(0,_0,_0,_0.75)_-15.68%,_rgba(0,_0,_0,_0.00)_23.32%,_rgba(0,_0,_0,_0.00)_51.09%,_rgba(0,_0,_0,_0.60)_76.04%,_rgba(0,_0,_0,_0.75)_93.99%)]"></div>
      <div className="absolute inset-0 z-[0]">
        <Image src={item?.cover_img} alt="cover" />
      </div>
      <div className="w-full relative z-[2]">
        <div className="flex justify-between items-end gap-[20px]">
          <div className="pl-[20px] lg:pl-[10px]">
            <TopVoted is_winner={item.is_winner} formatText />
            <div className="mt-[10px] text-[20px] max-w-[257px] lg:max-w-[241px] text-text-4 leading-[28px]">
              {item?.title}
            </div>
            <div className="mt-[5px] text-[14px] leading-[20px] text-text-4 font-bold capitalize">
              {item?.band_profile?.band_name}
            </div>
          </div>
          <div
            role="button"
            tabIndex={0}
            className="flex items-center justify-center rounded-[50%] w-[54px] h-[54px] mr-[10px] mb-[5px]"
            onClick={(e) => {
              setSelectedAudio(item)
              setTimeout(() => {
                setIsPreviewOpen(true)
              }, 600)
              e.stopPropagation()
            }}
          >
            <IconPlay />
          </div>
        </div>
        <Separator className="h-[1px] bg-cta-4 opacity-[0.3] mt-[10px] lg:mt-[20px]" />
        <div className="flex items-center justify-between w-full p-[10px] pl-[20px] lg:pl-[10px]">
          <div className="text-text-4 text-[12px] leading-[18px] font-medium">
            {days === 0
              ? "Today"
              : `Posted ${days} day${days > 1 ? "s" : ""} ago`}
          </div>
          <div>
            <UpvoteButton
              onClick={(e) => {
                onVote()
                e.stopPropagation()
              }}
              style={{ background: bgBtn() }}
              className={clsx(
                "relative flex justify-center items-center p-[10px] rounded-[100px] text-[12px] leading-[18px] hover:text-text-1 duration-300 h-[38px] z-[5]",
                vote ? "text-text-1" : "text-text-2 border border-cta-4",
                onLoad ? "cursor-wait" : "cursor-pointer"
              )}
              onLoad={onLoad}
              setOnMouseEnter={setOnMouseEnter}
              vote={vote}
              onMouseEnter={onMouseEnter}
            >
              <span className="mr-[2px] ml-[5px]">{vote_copy_text||"Inspired"}</span>
              <DotIcon fillColor={vote ? "fill-text-1" : "fill-text-2"} />
              <span className="ml-[2px]">{item?.total_vote || 0}</span>
            </UpvoteButton>
          </div>
        </div>
      </div>
    </div>
  )
}
MusicCard.propTypes = {
  vote: PropTypes.bool,
  item: PropTypes.any,
  dataAudio: PropTypes.any,
  setSelectedAudio: PropTypes.any,
  setDataMusic: PropTypes.any,
  setIsPreviewOpen: PropTypes.any,
}

const DotIcon = ({ fillColor }) => {
  return (
    <svg
      width="3"
      height="3"
      viewBox="0 0 3 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.62 2.13275C1.068 2.13275 0.612 1.67675 0.612 1.12475C0.612 0.57275 1.068 0.11675 1.62 0.11675C2.172 0.11675 2.628 0.57275 2.628 1.12475C2.628 1.67675 2.172 2.13275 1.62 2.13275Z"
        className={fillColor}
      />
    </svg>
  )
}
DotIcon.propTypes = {
  fillColor: PropTypes.string,
}

export default MusicCard
