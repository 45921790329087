/* eslint-disable react-hooks/exhaustive-deps */
import { Image } from "@/components/shared/image/image"
import { RemoveScroll } from "react-remove-scroll"
import { useContext, useRef, useState } from "react"
import { IconCloseCircle } from "../../components/submission-icons"
import { PreviewAudioContext } from "./preview-audio"
import { Dots, NextButton, PrevButton } from "@/components/swiper/swiper"
import { SwiperSlide, Swiper } from "swiper/react"
import { Autoplay } from "swiper/modules"
import "swiper/css"

const PreviewOfficialPress = () => {
  const {
    isShowOfficialImage: open,
    setIsShowOfficialImage,
    data,
  } = useContext(PreviewAudioContext)

  const swiperRef = useRef()
  const [activeIndex, setActiveIndex] = useState(0)
  const images = data?.official_press_photo?.length
    ? data?.official_press_photo
    : data?.band_logo

  return (
    open && (
      <div className="fixed inset-0 z-[20] bg-black/[.75] flex justify-center items-center">
        <RemoveScroll className="w-full h-screen">
          <div className="absolute w-full max-w-[500px] px-[15px] top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2">
            <div className="relative w-full aspect-square flex justify-center items-center">
              {images?.length > 0 ? (
                <>
                  {images?.length > 1 && (
                    <PrevButton className="" swiperRef={swiperRef} />
                  )}

                  <Swiper
                    modules={[Autoplay]}
                    autoplay={1000}
                    speed={1000}
                    className="w-full"
                    spaceBetween={24}
                    slidesPerView={1}
                    onSlideChange={(swiper) => {
                      setActiveIndex(swiper.activeIndex)
                    }}
                    onSwiper={(swiper) => {
                      swiperRef.current = swiper
                      setActiveIndex(swiper.activeIndex)
                    }}
                  >
                    {images?.map((slide, index) => (
                      <SwiperSlide key={slide?.name || index}>
                        <div className="relative w-full aspect-square rounded-[10px] overflow-hidden">
                          <Image
                            data-testid={`slide-img-${index}`}
                            alt="banner"
                            src={slide?.preview}
                            onLoad={() => {
                              URL.revokeObjectURL(slide?.preview)
                            }}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>

                  <Dots
                    activeIndex={activeIndex}
                    items={images?.map((item, index) => ({ id: index }))}
                  />

                  {images?.length > 1 && <NextButton swiperRef={swiperRef} />}
                </>
              ) : null}
            </div>
            <button
              className="absolute top-[-53px] right-[15px] w-[50px] h-[50px] text-white"
              onClick={() => setIsShowOfficialImage(false)}
            >
              <IconCloseCircle />
            </button>
          </div>
        </RemoveScroll>
      </div>
    )
  )
}

export default PreviewOfficialPress
