"use client"
import PropTypes from "prop-types"
import { cn } from "@/lib/utils"

const PreviewDetailTab = ({ active, onClick, hidden, children }) => {
  return (
    <button
      type="button"
      className={cn(
        "p-5 rounded-t-[20px] font-bold font-font-family-7",
        active ? "bg-[#191919]" : "bg-transparent text-[#969594]",
        hidden && "hidden"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

PreviewDetailTab.propTypes = {
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  hidden: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default PreviewDetailTab
