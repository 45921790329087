"use client"
import PropTypes from "prop-types"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { cn } from "@/lib/utils"
import { bgDetail } from "./preview-detail-band-profile"

const PreviewDetailSongAbout = ({
  data,
  isShowMoreSong,
  setIsShowMoreSong,
}) => {
  const isDesktop = useIsDesktop()
  const isTextOver =
    data?.about_song?.length > 545 || data?.caption?.length > 545

  return (
    (data?.about_song || data?.caption) && (
      <div
        className="p-[20px] rounded-[15px] lg:rounded-[20px] lg:rounded-tl-none flex flex-col gap-[10px] overflow-y-auto"
        style={{ background: isDesktop ? "#191919" : bgDetail }}
      >
        {!isDesktop && (
          <p className="text-base font-bold font-font-family-7 text-text-4">
            Tentang Lagu Ini
          </p>
        )}
        <div className="flex flex-col">
          <p
            className={cn(
              "relative overflow-hidden text-sm whitespace-pre-line",
              isDesktop || isShowMoreSong
                ? "max-h-none"
                : "max-h-[280px] line-clamp-[14]"
            )}
            style={{ color: "rgba(255, 255, 255, 0.75)" }}
          >
            {data?.about_song || data?.caption}
          </p>
          {!isDesktop && isTextOver && (
            <button
              className={cn(
                "w-fit self-end text-text-4 text-sm cursor-pointer",
                isShowMoreSong
                  ? "font-medium font-font-family-6 opacity-50 hover:opacity-100"
                  : "font-bold font-font-family-7"
              )}
              onClick={() => setIsShowMoreSong(!isShowMoreSong)}
            >
              {isShowMoreSong ? "Tampilkan sedikit" : "Selengkapnya"}
            </button>
          )}
        </div>
      </div>
    )
  )
}
PreviewDetailSongAbout.propTypes = {
  data: PropTypes.object.isRequired,
  isShowMoreSong: PropTypes.bool.isRequired,
  setIsShowMoreSong: PropTypes.func.isRequired,
}

export default PreviewDetailSongAbout
