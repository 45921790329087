/* eslint-disable react-hooks/exhaustive-deps */
"use client"
import React, { useEffect, useRef, useState } from "react"
import ProgressCard from "./progress-card/progress-card"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { motion } from "framer-motion"
import CardPil from "./card-pil/card-pil"
import { DropdownIcon } from "../shared/dropdown"
import { Image } from "../shared/image/image"
import { ClientApiList } from "@/lib/api/client-service"
import Link from "@/components/shared/link/link"
import { usePathname, useSearchParams } from "next/navigation"
import { formatNumber, nFormatter } from "../edit-profile/date-input/helper"
import PopOut from "../shared/popup-out/popup-out"
import { getGALinker, getGL } from "@/helper/ga"
import { cn } from "@/lib/utils"
import UseQueryParams from "@/hooks/use-query-params"
import { useDatalayer } from "@/hooks/use-datalayer"
import { useIsLogin } from "@/hooks/auth/use-is-login"

const Mail = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1668 12.3128L13.0668 12.5728C12.8202 12.6328 12.6268 12.8194 12.5735 13.0661L12.3402 14.0461C12.2135 14.5794 11.5335 14.7461 11.1802 14.3261L9.18683 12.0328C9.02683 11.8461 9.11349 11.5528 9.35349 11.4928C10.5335 11.2061 11.5935 10.5461 12.3735 9.60609C12.5002 9.45276 12.7268 9.43276 12.8668 9.57276L14.3468 11.0528C14.8535 11.5594 14.6735 12.1928 14.1668 12.3128Z"
        fill="url(#paint0_linear_2287_52665)"
      />
      <path
        d="M1.80011 12.3128L2.90011 12.5728C3.14678 12.6328 3.34011 12.8194 3.39344 13.0661L3.62678 14.0461C3.75344 14.5794 4.43344 14.7461 4.78678 14.3261L6.78011 12.0328C6.94011 11.8461 6.85344 11.5528 6.61344 11.4928C5.43344 11.2061 4.37344 10.5461 3.59344 9.60609C3.46678 9.45276 3.24011 9.43276 3.10011 9.57276L1.62011 11.0528C1.11344 11.5594 1.29344 12.1928 1.80011 12.3128Z"
        fill="url(#paint1_linear_2287_52665)"
      />
      <path
        d="M7.9987 1.33398C5.4187 1.33398 3.33203 3.42065 3.33203 6.00065C3.33203 6.96732 3.6187 7.85398 4.11203 8.59398C4.83203 9.66065 5.97203 10.414 7.2987 10.6073C7.52536 10.6473 7.7587 10.6673 7.9987 10.6673C8.2387 10.6673 8.47203 10.6473 8.6987 10.6073C10.0254 10.414 11.1654 9.66065 11.8854 8.59398C12.3787 7.85398 12.6654 6.96732 12.6654 6.00065C12.6654 3.42065 10.5787 1.33398 7.9987 1.33398ZM10.0387 5.85398L9.48536 6.40732C9.39203 6.50065 9.3387 6.68065 9.37203 6.81398L9.53203 7.50065C9.6587 8.04065 9.37203 8.25398 8.89203 7.96732L8.22536 7.57398C8.10536 7.50065 7.90536 7.50065 7.78536 7.57398L7.1187 7.96732C6.6387 8.24732 6.35203 8.04065 6.4787 7.50065L6.6387 6.81398C6.66536 6.68732 6.6187 6.50065 6.52536 6.40732L5.9587 5.85398C5.63203 5.52732 5.7387 5.20065 6.19203 5.12732L6.90536 5.00732C7.02536 4.98732 7.16536 4.88065 7.2187 4.77398L7.61203 3.98732C7.82536 3.56065 8.17203 3.56065 8.38536 3.98732L8.7787 4.77398C8.83203 4.88065 8.97203 4.98732 9.0987 5.00732L9.81203 5.12732C10.2587 5.20065 10.3654 5.52732 10.0387 5.85398Z"
        fill="url(#paint2_linear_2287_52665)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2287_52665"
          x1="14.3021"
          y1="14.0862"
          x2="9.29421"
          y2="10.4054"
          gradientUnits="userSpaceOnUse"
        >
          <stop style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="0.513479" style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="1" style={{ stopColor: "var(--icon_1)" }} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2287_52665"
          x1="6.51502"
          y1="14.0862"
          x2="1.5071"
          y2="10.4054"
          gradientUnits="userSpaceOnUse"
        >
          <stop style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="0.513479" style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="1" style={{ stopColor: "var(--icon_1)" }} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2287_52665"
          x1="12.082"
          y1="9.79232"
          x2="3.15289"
          y2="3.7726"
          gradientUnits="userSpaceOnUse"
        >
          <stop style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="0.513479" style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="1" style={{ stopColor: "var(--icon_1)" }} />
        </linearGradient>
      </defs>
    </svg>
  )
}

const Day = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0866 3.41437L11.0458 5.62521C11.04 5.92854 11.2325 6.33104 11.4775 6.51187L12.9241 7.60854C13.8516 8.30854 13.7 9.16604 12.5916 9.51604L10.7075 10.1052C10.3925 10.2044 10.06 10.5485 9.97831 10.8694L9.52914 12.5844C9.17331 13.9377 8.28664 14.0719 7.55164 12.8819L6.52498 11.2194C6.33831 10.916 5.89498 10.6885 5.54498 10.706L3.59664 10.8052C2.20248 10.8752 1.80581 10.0702 2.71581 9.00854L3.87081 7.66687C4.08664 7.41604 4.18581 6.94937 4.08664 6.63437L3.49164 4.74437C3.14748 3.63604 3.76581 3.02354 4.86831 3.38521L6.58914 3.95104C6.88081 4.04437 7.31831 3.98021 7.56331 3.79937L9.35998 2.50437C10.3341 1.81021 11.11 2.21854 11.0866 3.41437Z"
        fill="url(#paint0_linear_2287_52672)"
      />
      <path
        d="M13.5068 12.9412L11.7393 11.1737C11.5702 11.0046 11.2902 11.0046 11.121 11.1737C10.9518 11.3429 10.9518 11.6229 11.121 11.7921L12.8885 13.5596C12.976 13.6471 13.0868 13.6879 13.1977 13.6879C13.3085 13.6879 13.4193 13.6471 13.5068 13.5596C13.676 13.3904 13.676 13.1104 13.5068 12.9412Z"
        fill="url(#paint1_linear_2287_52672)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2287_52672"
          x1="12.8255"
          y1="12.613"
          x2="1.87899"
          y2="5.37549"
          gradientUnits="userSpaceOnUse"
        >
          <stop style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="0.513479" style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="1" style={{ stopColor: "var(--icon_1)" }} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2287_52672"
          x1="13.4687"
          y1="13.4403"
          x2="10.9426"
          y2="11.7382"
          gradientUnits="userSpaceOnUse"
        >
          <stop style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="0.513479" style={{ stopColor: "var(--icon_1)" }} />
          <stop offset="1" style={{ stopColor: "var(--icon_1)" }} />
        </linearGradient>
      </defs>
    </svg>
  )
}

const missions = [
  {
    icon: <Day />,
    label: `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Poinmu Hari Ini" : "Poin Lo Hari Ini"}`,
    field_allowed: "daily_exp",
    field_total: "daily_exp",
    isLimited: false,
    isMgm: false,
    href: null,
    have_tooltip: true,
    info: `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Poin Harian adalah jumlah total poin yang kamu kumpulkan dalam sehari" : "Poin Harian adalah akumulasi poin yang kamu kumpulkan dalam sehari."}`,
  },
  {
    icon: <Mail />,
    label: "Tantangan Ajak Teman",
    field_allowed: "total_invited",
    field_total: "total_invited",
    isLimited: false,
    isMgm: true,
    href: "/mgm",
    have_tooltip: false,
    info: null,
  },
]

export const variants = {
  show: {
    opacity: 1,
    y: "0",
    zIndex: 15,
    display: "block",
  },
  hide: {
    opacity: 0,
    y: "0",
    zIndex: 0,
    display: "none",
  },
  transition: {
    ease: "easeInOut",
    duration: 0.3,
  },
}

export function Loyalty(props) {
  const QUERYPARAM = {
    aldmic: "redirect-aldmic",
    point: "point",
    verify: "point-verify",
  }
  const { handleFilterQuery, handleManipulateQuery } = UseQueryParams()
  const isDesktop = useIsDesktop()
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const datalayer = useDatalayer()
  const { isLogin, isLoading } = useIsLogin()
  const isLoginComplete =
    isLogin && !isLoading && pathname !== "/allaccess-loading"
  const init = useRef(true)
  const [hideRule, setHideRule] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [failedAuth, setFailedAuth] = useState(false)
  const [propData, setPropData] = useState({ ...props })
  const {
    fist_name,
    accumulated_exp,
    accumulated_img_url,
    current_tier,
    current_tier_img_url,
  } = propData

  const handleGetData = async () => {
    try {
      const { data } = await ClientApiList.getLoyaltyTeaser()

      setPropData({ ...data.data.data })
      if (data.data.data.is_mgm) {
        await ClientApiList.getMGMUpdate()
      }
      init.current = false

      handleFilterQuery(QUERYPARAM.point)
    } catch (err) {
      console.log("[Err]", err)
    }
  }

  const handleSource = () => {
    switch (process.env.NEXT_PUBLIC_NAME) {
      case "amild":
        return "a-mild"
      case "djisamsoe":
        return "dss"
      case "sampoernakretek":
        return "sah"
      default:
        return process.env.NEXT_PUBLIC_NAME
    }
  }

  const sendFormPost = (
    { path, target, params, method = "post" },
    callback
  ) => {
    return new Promise((resolve) => {
      const form = document.createElement("form")
      form.target = target
      form.method = method
      form.action = path

      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          const hiddenField = document.createElement("input")
          hiddenField.type = "hidden"
          hiddenField.name = key
          hiddenField.value = params[key]
          form.appendChild(hiddenField)
        }
      }

      document.body.appendChild(form)
      form.submit(callback)
      setTimeout(() => {
        resolve("success")
        document.body.removeChild(form)
      }, 500)
    })
  }

  const handleClaim = async () => {
    const {
      data: { data: auth_data },
    } = await ClientApiList.getAuthData({
      ga: getGALinker(),
      gl: getGL(),
      platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
      redirect_url: `${process.env.NEXT_PUBLIC_META_URL}${pathname}`,
    })
    setResponseAldmic(auth_data.data.auth_data)

    let source = handleSource()
    const params = { source, auth_data: auth_data?.data?.auth_data }

    if (auth_data?.data?.auth_data) {
      setTimeout(() => {
        const response = sendFormPost({
          path:
            process.env.NEXT_PUBLIC_ENVIRONMENT === "production"
              ? `https://loyalty.aldmic.com/one-ux`
              : `https://loyalty-dev.aldmic.com/one-ux`,
          target: "_self",
          params,
        })
        // handleFilterQuery(QUERYPARAM.aldmic)
        setIsPopupOpen(false)
        // replace(pathname + "?" + paramsquery, { scroll: false })
      }, 1500)
    } else {
      console.log("failed")
      setFailedAuth(true)
    }
  }

  const handleToAldmic = () => {
    // handlePushQuery(false, [QUERYPARAM.aldmic, true])
    setIsPopupOpen(true)
  }

  const handleGetProfile = async () => {
    try {
      await ClientApiList.getProfileData()
      handleManipulateQuery(QUERYPARAM.point, true, QUERYPARAM.verify)
    } catch (err) {
      console.log("[Err]", err)
    }
  }

  useEffect(() => {
    if (searchParams.get(QUERYPARAM.verify)) handleGetProfile()
    if ((searchParams.get(QUERYPARAM.point) || init.current) && isLoginComplete)
      handleGetData()
  }, [searchParams, isLoginComplete])

  const [responseAldmic, setResponseAldmic] = useState(false)

  const switchBG = () => {
    if (process.env.NEXT_PUBLIC_NAME === "djisamsoe") {
      return "var(--cta_2)"
    } else {
      return "var(--background_3)"
    }
  }

  const datalayerClick = () => {
    datalayer.push({
      event: `general_event`,
      event_name: `click_loyalty_teaser_homepage`,
      feature: "loyalty",
    })
  }

  return (
    <>
      <div className="bg-transparent overflow-hidden lg:overflow-visible rounded-[10px] mb-[20px] mt-[10px] mx-[10px] relative z-[10] lg:col-start-2 lg:m-[0]">
        {!isDesktop ? <div className="absolute inset-0 z-[-1]"></div> : null}
        <div
          className={`group bg-bg-5 ease-in-out duration-300 lg:bg-header text-text-4 lg:text-text-2 hover:text-text-4 hover:bg-bg-5 active:bg-bg-5 p-[10px] pb-[5px] lg:pb-[10px] lg:rounded-[10px] flex justify-between gap-[5px] lg:justify-normal items-center relative overflow-hidden ${
            hideRule ? "!bg-bg-5 !text-text-4" : ""
          }`}
        >
          {isDesktop ? <div className="absolute inset-0 z-[-1]"></div> : null}
          <div className="flex justify-between items-center gap-[5px]">
            <div className="w-[36px] min-w-[36px] h-[36px] min-h-[36px] overflow-hidden relative bg-bg-2 rounded-[50%] border-[2px] border-icon-4 lg:w-[36px] lg:h-[36px] flex items-center justify-center">
              <Image
                className="max-w-[21px] max-h-[21px] inset-0"
                alt="cover"
                objectFit="cover"
                src="/assets/amild/loyalty/avatar.svg"
              />
            </div>
            <div
              data-testid="loyalty-greeting"
              className="text-[12px] hover:underline lg:text-[14px] font-medium font-font-family-6 leading-[16px] lg:leading-[19px]"
            >
              Hi,{" "}
              <Link onClick={() => datalayerClick()} href="/profil/br">
                <b className="font-black font-font-family-8">{fist_name}!</b>
              </Link>
            </div>
          </div>
          {isDesktop && accumulated_exp && (
            <div className="w-[1px] h-[24px] bg-cta-4 mx-[20px]" />
          )}
          {accumulated_exp ? (
            <div className="flex justify-between items-center gap-[5px] ">
              <button
                onClick={() => {
                  datalayerClick()
                  handleToAldmic()
                }}
                type="button"
                className="hover:underline"
              >
                <CardPil
                  img={accumulated_img_url}
                  className="hover:underline"
                  title={`${isDesktop ? formatNumber(accumulated_exp) : nFormatter(accumulated_exp, 1) || 0} Poin`}
                />
              </button>
              <Link
                className="block"
                onClick={() => datalayerClick()}
                href="/profil/br"
              >
                <CardPil
                  className="hover:underline"
                  isLink={true}
                  img={current_tier_img_url}
                  title={current_tier}
                />
              </Link>
              {isDesktop ? (
                <button
                  type="button"
                  onClick={() => {
                    datalayerClick()
                    setHideRule(!hideRule)
                  }}
                >
                  <CardPil
                    className={
                      !hideRule &&
                      `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "bg-cta-2" : "bg-bg-3"}group-hover:bg-bg-3`
                    }
                  >
                    <DropdownIcon open={hideRule} />
                  </CardPil>
                </button>
              ) : null}
            </div>
          ) : null}
        </div>

        {accumulated_exp ? (
          <motion.div
            key="animation-on-state"
            variants={isDesktop ? variants : ""}
            animate={!isDesktop || hideRule ? "show" : "hide"}
            className={
              "bg-bg-5 p-[10px] pt-[5px] lg:pt-[10px] lg:rounded-[10px] relative lg:absolute w-full overflow-hidden lg:mt-[20px]"
            }
          >
            {isDesktop ? <div className="absolute inset-0 z-[-1]"></div> : null}
            <div
              className={cn(
                "p-[10px] rounded-[10px] relative overflow-hidden"
                // process.env.NEXT_PUBLIC_NAME === "djisamsoe"
                //   ? "bg-cta-2"
                //   : "bg-bg-3"
              )}
              style={{ background: switchBG() }}
            >
              <div className="absolute inset-0 z-[0]"></div>
              <ul className="mb-0 relative z-[1]">
                {missions.map((item) => (
                  <li key={item.label} className="mb-[5px] lg:mb-[10px]">
                    <ProgressCard
                      missionDescription={item.label}
                      missonCountCurrent={propData[item.field_allowed]}
                      missionCountTotal={propData[item.field_total]}
                      missionIcon={item.icon}
                      isLimited={item.isLimited}
                      href={item.href}
                      isMgm={item.isMgm}
                      toolTip={item.have_tooltip}
                      info={item.info}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        ) : null}
      </div>
      <PopOut
        auth={responseAldmic}
        isPopupOpen={isPopupOpen}
        handleClaim={handleClaim}
        failedAuth={failedAuth}
        handleClose={setIsPopupOpen}
      />
    </>
  )
}
