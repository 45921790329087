"use client";
import React, { useRef, useState } from "react";
import { SwiperSlide } from "swiper/react";

import "swiper/css";

// custom comp. import
import CatWithIcon from "../../shared/cat-with-icon/cat-with-icon";
import { SwiperComp } from "@/components/swiper/swiper";

export default function TierCategories({
  categories = [],
  selectedCategory,
  isLoading,
}) {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleRenderList = () => {
    if (isLoading) {
      return [1, 2, 3, 4, 5].map((item, index, arr) => (
        <SwiperSlide
          key={item}
          className={`!w-fit ${index === 0 ? "!ml-[10px]" : ""}`}
        >
          <div
            data-testid={`loader-${index}`}
            className="h-[42px] lg:h-[49px] w-full min-w-[50px] bg-[#D9D9D9] animate-pulse rounded-[10px]"
          ></div>
        </SwiperSlide>
      ));
    } else {
      return categories.map((category, index) => {
        return (
          <SwiperSlide
            key={category.id}
            className={`!w-fit ${index === 0 ? "!ml-[10px]" : ""}`}
          >
            <CatWithIcon
              size="medium"
              className="lg:leading-[20px] lg:!py-[13.5px]"
              categoryName="reward-tier"
              intent={
                selectedCategory === category.label ? "active" : "default"
              }
              icon={category.icon}
            >
              {category.label}
            </CatWithIcon>
          </SwiperSlide>
        );
      });
    }
  };

  return (
    <>
      <div
        id="Tier_Categories"
        className="text-text-2 pl-[5px] lg:pl-0 text-[20px] font-medium font-font-family-6 mb-[15px] leading-[27px]"
      >
        {process.env.NEXT_PUBLIC_NAME !== "djisamsoe" ? (
          <>Pengalaman Eksklusif Berdasarkan Tier Lo</>
        ) : (
          <>
            Pengalaman Eksklusif Berdasarkan <i>Tier</i>-mu
          </>
        )}
      </div>
      <div className="relative w-[100%] h-[42px] lg:h-[50px] mb-[15px]  flex gap-[5px]">
        <div className="absolute left-[-10px] right-0">
          <SwiperComp
            setActiveIndex={setActiveIndex}
            ref={swiperRef}
            slidesPerView={"auto"}
            spaceBetween={5}
          >
            {handleRenderList()}
          </SwiperComp>
        </div>
      </div>
    </>
  );
}
