import ButtonAction from "@/components/shared/button-action/button-action"
import { Image } from "@/components/shared/image/image"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { capitalizeFirstLetter, handleChances } from "@/helper/utils"
import { useWindowSize } from "@/hooks/use-window-size"
import clsx from "clsx"
import { usePathname, useRouter } from "next/navigation"
import PropTypes from "prop-types"
import { useRef } from "react"
import DOMPurify from "isomorphic-dompurify"

const SubmissionPopup = ({
  open,
  close,
  isPopupDelete = false,
  isPopupConfirm = false,
  deleteAudio,
  setStage,
  popupData,
  handleSubmit,
  isOnHold,
  nextUrl,
}) => {
  const brandName = process.env.NEXT_PUBLIC_NAME
  const person = brandName === "djisamsoe" ? "kamu" : "lo"
  const { width } = useWindowSize()
  const isDesktop = width >= 1024
  const { replace } = useRouter()
  const introDesc = useRef(null)
  const availTitle = useRef(null)
  const pathname = usePathname()

  const handleConfirm = () => {
    isPopupConfirm ? isPopupDelete && deleteAudio() : setStage(1)
    close()
  }

  const handleCancel = () => {
    isPopupConfirm ? !isPopupDelete && handleSubmit() : replace("/br")
    close()
  }

  const textTitle = () => {
    const contentConfirm = isPopupDelete ? "Yakin mau hapus?" : "Siap Submit?"
    return isPopupConfirm
      ? contentConfirm
      : popupData?.success_intro_title || "THANK YOU!"
  }

  const textBody = () => {
    const contentConfirm = isPopupDelete
      ? `${capitalizeFirstLetter(person)} harus upload ulang file ${person} buat submit karya`
      : `Udah yakin buat submit? Setelah submit, karya ${person} gak bisa diubah lagi.`
    return isPopupConfirm ? (
      contentConfirm
    ) : (
      <div
        className="text-center text-[16px] leading-[24px] mb-[20px]"
        ref={introDesc}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(popupData?.success_intro_desc),
        }}
      ></div>
    )
  }

  const intentButtonConfirm =
    popupData?.remainingChances > 0 ? "primary" : "primary_disable"

  const buttonConfirm = () => {
    const contentConfirm = isPopupDelete ? "Ya" : "Batal"
    return isPopupConfirm
      ? contentConfirm
      : popupData?.success_cta_1 || "Submit"
  }

  const buttonCancel = () => {
    const contentConfirm = isPopupDelete ? "Batal" : "Submit"
    return isPopupConfirm
      ? contentConfirm
      : popupData?.success_cta_2 || "Back To Homepage"
  }

  return (
    <PopupAction
      isShowing={open}
      onClickBtnX={close}
      className={"!p-[10px]"}
      zIndex="z-[20]"
    >
      <div className="w-full h-full p-[15px] rounded-[10px] border-[2px] border-cta-3 border-dashed">
        {isPopupConfirm && (
          <div className="overflow-hidden h-full relative aspect-[304/177] rounded-[15px]">
            <Image
              alt="banner"
              src={`/assets/${brandName}/mission/popup-confirm-${isPopupDelete ? "exit" : "submit"}.png`}
            />
          </div>
        )}
        <div
          className={clsx(
            "my-[20px] max-w-[304px] text-text-2 flex flex-col",
            isPopupConfirm ? "gap-[5px]" : "gap-[10px]"
          )}
        >
          <p className="text-xl font-black font-font-family-8 capitalize">
            {textTitle()}
          </p>
          <p
            className={clsx(
              "text-center font-font-family-5",
              isDesktop ? "text-base" : "text-sm"
            )}
          >
            {textBody()}
          </p>

          {/* CHANCES */}
          {!isPopupConfirm && (
            <div className="p-[10px] rounded-[8px] bg-bg-1">
              {popupData?.remainingChances > 0 && (
                <div
                  className="text-[16px] leading-[24px] font-bold text-center mb-[5px] font-font-family-7"
                  ref={availTitle}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      popupData?.isChances
                        ? handleChances(
                            popupData?.success_avail_title,
                            popupData
                          )
                        : popupData?.success_ranout_title
                    ),
                  }}
                ></div>
              )}
              <div
                className="text-[16px] leading-[24px] text-center mb-[30px]"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    popupData?.isChances
                      ? popupData?.success_avail_desc ||
                          `Ayo submit lagi biar peluang karya ${person} terpilih lebih besar.`
                      : popupData?.success_ranout_desc ||
                          `Kesempatan ${person} buat submit ulang submisinya udah habis.`
                  ),
                }}
              ></div>
            </div>
          )}
        </div>

        <div className={clsx("flex gap-[10px]", !isPopupConfirm && "flex-col")}>
          {popupData?.remainingChances >= 1 || !nextUrl ? (
            <ButtonAction
              onClick={() => handleConfirm()}
              className="py-[13px] px-[20px]"
              intent={isPopupConfirm ? "secondary" : intentButtonConfirm}
              disabled={!isPopupConfirm && popupData?.remainingChances < 1}
            >
              {buttonConfirm()}
            </ButtonAction>
          ) : (
            <ButtonAction
              onClick={() => {
                isOnHold
                  ? replace(
                      pathname +
                        `?holdstep=brandpref&link=${encodeURIComponent(nextUrl)}`
                    )
                  : replace(nextUrl)
              }}
              className="py-[13px] px-[20px]"
              intent={"primary"}
            >
              Explore Tantangan Lain
            </ButtonAction>
          )}
          <ButtonAction
            data-testid="popup-cancel"
            onClick={() => handleCancel()}
            className="py-[13px] px-[20px]"
            intent={isPopupConfirm ? "primary" : "secondary"}
          >
            {buttonCancel()}
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}

SubmissionPopup.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  isPopupConfirm: PropTypes.bool,
  isPopupDelete: PropTypes.bool,
  deleteAudio: PropTypes.func,
  setStage: PropTypes.func,
  popupData: PropTypes.object,
  handleSubmit: PropTypes.func,
}

export default SubmissionPopup
