"use client"
import { usePathname, useRouter } from "next/navigation"
import React from "react"
import { twMerge } from "tailwind-merge"
import PropTypes from "prop-types"
import UseQueryParams from "@/hooks/use-query-params";

export default function ButtonBack({
  className,
  icon,
  onClick,
  isEngagment = false,
  ...props
}) {
  const { back } = useRouter()
  const pathname = usePathname()
  const {handleFilterQuery} = UseQueryParams()

  const handleClick = () => {
    back({ scroll: true })
  }

  const bgBtnBack = () => {
    if (process.env.NEXT_PUBLIC_NAME === "sampoernakretek" || process.env.NEXT_PUBLIC_NAME === "marlboro")
      return "var(--background_1)"
    return "var(--cta_2)"
  }

  const hideBtn = pathname.includes("/why-not")

  if (hideBtn) return null

  return (
    <button
      onClick={onClick ? onClick : handleClick}
      style={{ background: bgBtnBack(), border: "1px solid var(--cta_4)" }}
      className={twMerge(
        "absolute z-[3] top-[10px] min-h-[50px] left-[10px] py-[12px] px-[15px] rounded-[10px] border-[1px] lg:flex items-center lg:gap-[5px] lg:p-[11px] lg:left-[30px] lg:top-[30px]",
        className
      )}
      {...props}
    >
      {icon || <Icon />}
      <div
        className={`hidden lg:block font-bold font-font-family-7 text-[14px] text-text-2`}
      >
        Kembali
      </div>
    </button>
  )
}

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M20.9999 11H6.41394L11.7069 5.707L10.2929 4.293L2.58594 12L10.2929 19.707L11.7069 18.293L6.41394 13H20.9999V11Z"
        className="fill-icon-2"
      />
    </svg>
  )
}

ButtonBack.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  onClick: PropTypes.func,
  props: PropTypes.object,
}
