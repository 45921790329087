import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"
import { IconPlus } from "./submission-icons"
import { useState } from "react"

const SubmissionCollapse = ({ name, title, index, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeSub, setActiveSub] = useState(0)
  const containerClass =
    "bg-bg-1 rounded-[10px] border-[1px] border-cta-4 overflow-hidden my-[10px]"

  const toggleDropdown = (index) => {
    setIsOpen(!isOpen)
    setActiveSub(index)
  }

  return (
    <div className={containerClass}>
      <button
        type="button"
        onClick={() => toggleDropdown(index)}
        className="w-full p-[10px] pb-[15px] text-left text-text-2 text-sm font-bold font-font-family-7 flex justify-between items-center capitalize"
      >
        <span>{title}</span>
        <span>
          <IconPlus
            animate={isOpen && index == activeSub ? "open" : "closed"}
          />
        </span>
      </button>
      <AnimatePresence>
        {isOpen && index === activeSub && (
          <motion.div
            key={name}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.2 }}
            className="p-[10px] pt-0 flex flex-col gap-[10px]"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

SubmissionCollapse.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  index: PropTypes.number,
  children: PropTypes.any,
}

export default SubmissionCollapse
