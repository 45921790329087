"use client"
import React, { Suspense, useState } from "react"
import clsx from "clsx"
import Link from "@/components/shared/link/link"
import { usePathname, useSearchParams } from "next/navigation"
import PropTypes from "prop-types"

import { useIsDesktop, useIsTablet } from "@/hooks/use-is-mobile"
import { Image } from "@/components/shared/image/image"
import { useDatalayer } from "@/hooks/use-datalayer"
import { cn } from "@/lib/utils"
import { useReqUrl } from "@/hooks/auth/use-req-url"

function NavItemFallback() {
  return <div>Fallback</div>
}

function SuspendedNavItem({
  href,
  label,
  icon,
  iconSelected,
  type,
  className,
  isCol,
  rightSlot,
  id,
  eventMenu,
  isOpen,
}) {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()
  const datalayer = useDatalayer()
  const [onHover, setOnHover] = useState(false)

  const { execute: login } = useReqUrl({
    redirectUrl: href,
  })

  const hrefToLink = () => {
    if (String(label).toLowerCase() === "riders zone") return "#"
    if (type !== "open_menu") return href
    let links = { pathname, query: null }
    const newQuery = {
      ...Object.fromEntries(Array.from(searchParams.entries())),
      open: searchParams.get("open") === "true" ? "false" : "true",
    }
    links.query = newQuery
    return links
  }

  const onClickDatalayer = () => {
    datalayer.push({
      event: `general_event`,
      event_name: eventMenu
        ? "click_burger_navigation"
        : `click_${label?.split(" ").join("_").toLowerCase()}_navigation`,
      feature: "customized navigation",
      button_name: label?.toLowerCase(),
      campaign_name:
        type === "special" ? href.split("/").filter(Boolean)[1].split("-").join(" ") : "all",
      engagement_type: "interaction",
    })
  }

  const menuPage =
    pathname.includes("about") ||
    pathname.includes("exclusive-experience") ||
    pathname.includes("events") ||
    (searchParams.has("open") && searchParams.get("open") === "true") ||
    isOpen

  const activePage =
    (pathname === href &&
      (searchParams.has("open") || !searchParams.has("open")) &&
      searchParams.get("open") !== "true" &&
      !isOpen) ||
    (id === 4 && menuPage)

  const mobileTextColor =
    process.env.NEXT_PUBLIC_NAME === "marlboro"
      ? "text-text-2 opacity-75"
      : "text-text-3"

  return (
    <Link
      className={cn(
        className,
        `flex gap-[5px] items-center block grow-0 shrink-0 basis-[65px] transition-300`,
        process.env.NEXT_PUBLIC_NAME === "marlboro"
          ? "hover:text-text-2 hover:opacity-100"
          : "hover:text-text-1",
        activePage
          ? process.env.NEXT_PUBLIC_NAME === "marlboro"
            ? "text-text-2 opacity-100"
            : "text-text-1"
          : mobileTextColor,
        isCol ? "flex-col" : "flex-row",
        isDesktop ? "p-[10px]" : "py-[10px]",
        "flex"
      )}
      href={hrefToLink()}
      data-testid="nav-item-link"
      passHref
      onClick={() => {
        String(label).toLowerCase() === "riders zone" && login()
        onClickDatalayer()
      }}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
    >
      <div className={`min-w-[24px] min-h-[24px] relative`}>
        <Image
          data-testid="nav-item-icon"
          src={activePage || onHover ? iconSelected : icon}
          alt={label}
          className="absolute inset-0"
        />
      </div>
      <div
        data-testid="nav-item-label"
        className={clsx(
          "text-center capitalize",
          isTablet ? "text-[10px]" : "text-base"
        )}
      >
        {label || "label"}
      </div>
      {rightSlot}
    </Link>
  )
}

SuspendedNavItem.propTypes = {
  href: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  isCol: PropTypes.bool,
  rightSlot: PropTypes.object,
  icon: PropTypes.any,
  iconSelected: PropTypes.any,
  id: PropTypes.number,
  eventMenu: PropTypes.string,
}

function NavItem(props) {
  return (
    <Suspense fallback={<NavItemFallback />}>
      <SuspendedNavItem {...props} />
    </Suspense>
  )
}

export default NavItem
