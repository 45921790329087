"use client"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import NavItem from "@/components/nav/nav-item/navItem"
import {
  motion,
  useScroll,
  useMotionValueEvent,
  useAnimationControls,
} from "framer-motion"
import { useViewportSize } from "@mantine/hooks"
import NavImage from "@/components/nav/nav-image/navImage"
import { useIsTablet } from "@/hooks/use-is-mobile"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { ClientApiList } from "@/lib/api/client-service"
import { cn } from "@/lib/utils"
import { usePathname, useSearchParams } from "next/navigation"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import { iframeHidesNav } from "../iframe/data-hide"

const staticData = {
  brStatic: {
    home: {
      label: "Home",
      href: "/br",
    },
    profile: {
      label: "Profile",
      href: "/br/profile",
    },
  },
  ubStatic: {
    home: {
      label: "Home",
      href: "/ub",
    },
    profile: {
      label: "Menu",
      href: "/ub/menu",
    },
  },
}

export function AppNav({ nav_items = [], nav_items_ub = [], mainNav }) {
  const { scrollY, scrollYProgress } = useScroll()
  let { height } = useViewportSize()
  const controls = useAnimationControls()
  const { isLogin, isLoading } = useIsLogin()
  const pathname = usePathname()
  const searchParams = useSearchParams()
  // const renderedMenu = isLogin ? "brStatic" : "ubStatic"
  const [navItems, setNavItems] = useState([...nav_items])
  const [navItemsUb, setNavItemsUb] = useState([...nav_items_ub])

  const { execute: autoLoginPage } = useReqUrl({
    redirectUrl: typeof window !== "undefined" && window.location.href,
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
    page: "login",
  })

  const { execute: autoRegisterPage } = useReqUrl({
    redirectUrl: typeof window !== "undefined" && window.location.href,
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
    page: "register",
  })

  useEffect(() => {
    if (searchParams.get("to") === "allaccess" && !isLoading && !isLogin) {
      autoLoginPage()
    } else if (
      searchParams.get("to") === "allaccess-register" &&
      !isLoading &&
      !isLogin
    ) {
      autoRegisterPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, isLoading, isLogin])

  // const handleGetApp = async () => {
  //   try {
  //     const { data } = await ClientApiList.getGlobalConfig()
  //     const navData = data.data.ui_schema.navMenu.component_props
  //     setNavItems([...navData.nav_items])
  //     setNavItemsUb([...navData.nav_items_ub])
  //   } catch (err) {
  //     console.log("err", err)
  //   }
  // }

  // useEffect(() => {
  //   handleGetApp()
  // }, [])

  const listToRender = isLogin ? navItems : navItemsUb

  const isTablet = useIsTablet()

  useMotionValueEvent(scrollY, "change", (latest) => {
    // console.log(
    // "latest", latest,
    // scrollY.getPrevious() ,
    // latest < scrollY.getPrevious(),
    // height,
    // latest,
    // document.body.offsetHeight,
    // height + latest >= document.body.offsetHeight
    // scrollY.getPrevious(),
    // scrollYProgress.getPrevious(),
    // document.body.offsetHeight,
    // window.innerHeight,
    // scrollYProgress.get(),
    // latest > scrollY.getPrevious()
    // );
    if (latest <= 1) {
      controls.start({
        y:
          (String(pathname).match(/^\/[\w-.]+\/[br|ub]{2}$/g) &&
            !pathname.includes("profil")) ||
          (iframeHidesNav.find((path) => pathname.includes(path)) &&
            pathname !== "/br/why-not/inspiration/music/iframe/why-not" &&
            pathname !== "/why-not/br" && !pathname.includes('maha-karaoke'))
            ? 100
            : 0,
        // opacity: 1,
      })
    } else if (
      latest < scrollY.getPrevious() ||
      height + latest >= document.body.offsetHeight
    ) {
      controls.start({
        y: 0,
        // opacity: 1,
        transition: {
          duration: 0.3,
          ease: "easeOut",
        },
      })
    } else {
      controls.start({
        y: 100,
        // opacity: 0,
        transition: {
          duration: 0.2,
          ease: "easeOut",
        },
      })
    }
  })

  const hideNav =
    (pathname.includes("/cc-bridging") ||
      pathname.includes("/maintenance") ||
      pathname.includes("/amild-offline") ||
      pathname.includes("/redland-marlboro") ||
      pathname.includes("/why-not")) &&
    pathname !== "/br/why-not/inspiration/music/iframe/why-not" &&
    pathname !== "/why-not/br" &&
    !pathname.includes("maha-karaoke")

  return (
    <>
      {isTablet && !hideNav && (
        <motion.nav
          initial={{
            y:
              (String(pathname).match(/^\/[\w-.]+\/[br|ub]{2}$/g) &&
                !pathname.includes("profil")) ||
              (iframeHidesNav.find((path) => pathname.includes(path)) &&
                pathname !== "/br/why-not/inspiration/music/iframe/why-not" &&
                pathname !== "/why-not/br" &&
                !pathname.includes("maha-karaoke"))
                ? 100
                : 0,
          }}
          animate={controls}
          className={cn(
            `px-4 fixed z-[15] bottom-[56px] left-0 right-0 bg-navbar lg:hidden text-text-5`,
            pathname.includes("joget-ambil-asiknya/br") ? "hidden" : "",
            pathname.includes('maha-karaoke') ?'bottom-0':''
          )}
          style={{ background: "var(--navigation_bar)" }}
        >
          <ul className="flex justify-between items-end">
            {/* <NavItem onClick={handleNavItemDatalayer} label={staticData[renderedMenu].home.label} href={staticData[renderedMenu].home.href} /> */}
            {listToRender.map((item, index) => {
              if (
                listToRender.length % 2 === 1 &&
                index + 1 === (listToRender.length + 1) / 2 &&
                item.type !== "default"
              )
                return (
                  <NavImage
                    key={item.label}
                    label={item.label}
                    href={item.href}
                    icon={item.icon_url}
                  />
                )
              return (
                <NavItem
                  isCol
                  key={item.label}
                  label={item.label}
                  href={item.href}
                  type={item.type}
                  icon={item.icon_url}
                  iconSelected={item.icon_url_selected}
                  id={item.id}
                />
              )
            })}
            {/* <NavItem onClick={handleNavItemDatalayer} label={staticData[renderedMenu].profile.label} href={staticData[renderedMenu].profile.href} /> */}
          </ul>
        </motion.nav>
      )}
    </>
  )
}

AppNav.propTypes = {
  nav_items: PropTypes.array,
  nav_items_ub: PropTypes.array,
  mainNav: PropTypes.bool,
}
