import ButtonAction from "@/components/shared/button-action/button-action"
import { Image } from "@/components/shared/image/image"
import PopupAction from "@/components/shared/popup-action/popup-action"
import { handleChances, toProxy } from "@/helper/utils"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname, useRouter } from "next/navigation"
import DOMPurify from "isomorphic-dompurify"
import PropTypes from "prop-types"
import { useRef, useState } from "react"

const successPopup = {
  title: "Tebakan lo benar!",
  description:
    "Mainkan kembali tantangan untuk bisa berkesempatan menjadi pemenang dengan memiliki EXP tertinggi.",
  cta_label: "Eksplor Tantangan Selanjutnya",
  cta_cancel: "Cek keseruan lainnya",
}

export const PopupSubmission = ({
  popupData,
  isOpen,
  point,
  nextUrl,
  onClickBtnX,
  isOnHold,
}) => {
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const { replace } = useRouter()
  const introDesc = useRef(null)
  const availTitle = useRef(null)
  const [loading, setLoading] = useState(false)
  const popupUnlimited = popupData?.popup_type === "unlimited"

  const handleConfirm = () => {
    setLoading(true)
    onClickBtnX ? onClickBtnX() : replace(pathname)
    setLoading(false)
  }

  return (
    <PopupAction
      isShowing={isOpen}
      href={pathname}
      zIndex="z-[20]"
      onClickBtnX={onClickBtnX}
    >
      <div className="max-w-[304px]">
        <div className="absolute rounded-[10px] border-[3px] border-cta-3 border-dotted inset-[10px] z-[-1]"></div>

        {popupUnlimited && (
          <div className="min-w-[304px] min-h-[177px] bg-bg-5 rounded-[10px] overflow-hidden relative mb-[20px]">
            <Image
              data-testid="popup-submission-image"
              alt="cover"
              fill
              style={{ objectFit: "cover", objectPosition: "center" }}
              className="absolute inset-0"
              src={toProxy(
                isMobile
                  ? popupData?.mobile_img_url_confirm
                  : popupData?.desktop_img_url_confirm
              )}
            />
          </div>
        )}

        {/* TITLE */}
        <div
          data-testid="popup-submission-title"
          className="text-[20px] font-black font-font-family-8 leading-[27px] mb-[5px] text-text-2"
        >
          {popupData?.subtitle_confirmation || popupData?.success_intro_title}
        </div>

        {/* POINT */}
        {popupUnlimited && point > 0 && (
          <div className="flex items-center gap-[5px] justify-center mb-[5px]">
            <div className="min-h-[20px] w-fit min-w-[20px] relative">
              <Image
                alt="cover"
                fill
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  position: "absolute",
                  inset: 0,
                }}
                src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`}
              />
            </div>
            <div className="text-[20px] text-text-1 leading-[27px] font-black font-font-family-8">
              {point} Poin!
            </div>
          </div>
        )}

        {/* DESCRIPTION */}
        <div
          data-testid="popup-submission-description"
          className="text-[14px] text-text-2 md:text-[16px] font-medium font-font-family-6 mb-[20px] leading-[20px] md:leading-[25px]"
          ref={introDesc}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              popupData?.desc_confirmation || popupData?.success_intro_desc
            ),
          }}
        />

        {/* CHANCES */}
        {!popupUnlimited && (
          <div className="p-[10px] mb-[20px] rounded-[8px] bg-bg-1">
            {popupData?.remainingChances > 0 && (
              <div
                className="text-[16px] leading-[24px] font-bold text-center mb-[5px] font-font-family-7"
                ref={availTitle}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    popupData?.isChances
                      ? handleChances(popupData?.success_avail_title, popupData)
                      : popupData?.success_ranout_title
                  ),
                }}
              />
            )}
            <div
              className="text-[16px] leading-[24px] text-center"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  popupData?.isChances
                    ? popupData?.success_avail_desc
                    : popupData?.success_ranout_desc
                ),
              }}
            />
          </div>
        )}

        <div className={"flex flex-col gap-[10px]"}>
          {nextUrl && (
            <ButtonAction
              onClick={() => {
                isOnHold
                  ? replace(pathname + `?holdstep=brandpref&link=${encodeURIComponent(nextUrl)}&hide-sub-rule=true&show-submission-popup=true`)
                  : replace(nextUrl)
              }}
              intent="primary"
            >
              {successPopup.cta_label}
            </ButtonAction>
          )}

          {!popupUnlimited && (
            <ButtonAction
              onClick={() => handleConfirm()}
              intent={
                popupData?.remainingChances > 0 ? "primary" : "primary_disable"
              }
              disabled={popupData?.remainingChances < 1 || loading}
            >
              {popupData?.success_cta_1 || "Submit"}
            </ButtonAction>
          )}

          <ButtonAction
            onClick={() => replace("/br")}
            intent={nextUrl || !popupUnlimited ? "secondary" : "primary"}
          >
            {!popupUnlimited
              ? popupData?.success_cta_2 || "Back To Homepage"
              : successPopup.cta_cancel}
          </ButtonAction>
        </div>
      </div>
    </PopupAction>
  )
}
PopupSubmission.propTypes = {
  popupData: PropTypes.object,
  isOpen: PropTypes.bool,
  point: PropTypes.any,
  nextUrl: PropTypes.any,
  onClickBtnX: PropTypes.func,
}
