"use client"
import { Image } from "@/components/shared/image/image"
import dynamic from "next/dynamic"
import Separator from "../slide-menu/separator/separator"
import "video.js/dist/video-js.css"
import clsx from "clsx"
import { useIsMobile, useIsTablet } from "@/hooks/use-is-mobile"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { ToastComponent } from "../toast"
import { useInView } from "react-intersection-observer"
import { getPublishDate, useDatalayer } from "@/hooks/use-datalayer"
import { useIsLogin } from "@/hooks/auth/use-is-login"
import { ClientApiList } from "@/lib/api/client-service"
import { useSearchParams } from "next/navigation"
import { useScrollTracker } from "react-scroll-tracker"
import Link from "@/components/shared/link/link"
import { useReqUrl } from "@/hooks/auth/use-req-url"
import UseQueryParams from "@/hooks/use-query-params"
import { useShowLoginPopup } from "@/hooks/auth/use-login-popup"
import { DescriptionArticle } from "../shared/description-article/description-article"
import { cn } from "@/lib/utils"
import { postMessage } from "@/helper/utils"

const DynamicVideoJs = dynamic(
  () => import("@/lib/components/video-js/video-js"),
  {
    ssr: false,
  }
)

export function ArticleDetails({ data = {} }) {
  const QUERYPARAM = "point"
  const { handlePushQuery } = UseQueryParams()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const datalayer = useDatalayer()
  const { isLoading, isLogin } = useIsLogin()
  const searchParams = useSearchParams()
  const { scrollY } = useScrollTracker()

  const [point, setPoint] = useState(false)
  const [finished, setFinished] = useState(false)
  const [firstScroll, setFirstScroll] = useState(false)
  const [secondScroll, setSecondScroll] = useState(false)
  const [thirdScroll, setThirdScroll] = useState(false)
  const [finishScroll, setFinishScroll] = useState(false)
  const [popupLogin, setPopupLogin] = useState(false)

  const { execute: login } = useReqUrl()

  const updateDetail = async () => {
    const { data: detailData } = await ClientApiList.getDetailArticle({
      string_id: data.string_id,
      is_branded: data.is_branded,
    })

    if (detailData) {
      setFinished(detailData?.data?.data?.finished)
    }
  }

  useEffect(() => {
    if (!isLoading) updateDetail()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoading])

  useEffect(() => {
    if (searchParams.get("point")) updateDetail()
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [searchParams])

  const content = (
    <div
      className={clsx(
        "relative w-full h-fit max-w-[697px] bg-bg-3",
        isMobile
          ? "aspect-[375/282]"
          : "aspect-[697/524] rounded-[10px] overflow-hidden"
      )}
    >
      {data?.format === "video" ? (
        <DynamicVideoJs
          fill
          poster={data?.thum_video_url}
          src={data?.video_url}
          duration={data?.duration}
          branded={data?.is_branded == 1 ? "Yes" : "No"}
          category={data?.category}
          campaign={data?.campaign}
          title={data?.title}
          videoId={data?.id}
          publishDate={data?.start_date}
          aspectRatio="4:3"
          isRegular={data?.is_regular}
          isSpecial={data?.is_special_engagement}
          activityId={data?.activity_id}
        />
      ) : (
        <Image
          alt="cover"
          src={isMobile ? data.mobile_image_url : data.desktop_image_url}
        />
      )}
    </div>
  )

  const [bottomRef, inView] = useInView({
    triggerOnce: true,
  })

  const bottom =
    typeof window !== "undefined" &&
    window.innerHeight + window.scrollY >= document.body.offsetHeight

  const scrollDepth = (progress) => {
    const forDatalayer = {
      event: "general_event",
      event_name: "scroll_depth_article",
      feature: "content",
      content_brand: data?.is_branded == 0 ? "unbranded" : "branded",
      content_type: data?.format,
      content_category: data?.category.toLowerCase(),
      content_name: data?.title.toLowerCase(),
      content_progress: progress,
      publish_date: getPublishDate(data?.start_date),
      engagement_type: "content",
      campaign_name: data?.is_special_engagement
        ? "why not 2025"
        : data?.campaign?.toLowerCase(),
    }
    if (data?.is_special_engagement) {
      postMessage(
        { action: "datalayer", datalayer: forDatalayer },
        process.env.NEXT_PUBLIC_META_URL
      )
    } else {
      datalayer.push(forDatalayer)
    }
  }

  useEffect(() => {
    if (((scrollY >= 25 && scrollY < 50) || bottom) && !firstScroll) {
      scrollDepth("25%")
      setFirstScroll(true)
    } else if (
      ((scrollY >= 50 && scrollY < 75 && firstScroll) ||
        (bottom && firstScroll)) &&
      !secondScroll
    ) {
      scrollDepth("50%")
      setSecondScroll(true)
    } else if (
      ((scrollY >= 75 && scrollY < 100 && secondScroll) ||
        (bottom && secondScroll)) &&
      !thirdScroll
    ) {
      scrollDepth("75%")
      setThirdScroll(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [scrollY, firstScroll, secondScroll, thirdScroll])

  useEffect(() => {
    if (bottom && !finishScroll && thirdScroll) {
      scrollDepth("100%")
      setFinishScroll(true)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [bottom, finishScroll, thirdScroll])

  const logPoint = async () => {
    if (data?.format != "video") {
      const { data: dataPoint } = await ClientApiList.getPointLogging({
        content_brand: data?.is_branded == 0 ? "unbranded" : "branded",
        content_type: data?.format,
        id: data.id,
        is_regular: data.is_regular,
      })

      if (dataPoint?.data.data.point !== undefined) {
        setPoint(dataPoint?.data?.data.point)
        handlePushQuery(false, [QUERYPARAM, true])
      }
      if (data?.is_special_engagement) {
        postMessage({ activityId: data?.activity_id })
      }
    }
  }

  useEffect(() => {
    if (inView) logPoint()
    // eslint-disable-next-line
  }, [inView])

  useShowLoginPopup({
    showWhen: bottom && !popupLogin,
    closePath: "",
    platform: `${process.env.NEXT_PUBLIC_NAME} x all`,
    onOpen: () => setPopupLogin(true),
  })

  const titleBrand =
    process.env.NEXT_PUBLIC_NAME === "djisamsoe"
      ? `Kamu berhasil raih ${point} poin`
      : `Lo berhasil dapetin ${point} poin!`

  const ctaImg = isMobile
    ? data.cta_mobile_image_url
    : data.cta_desktop_image_url

  // const ctaImgUB = isMobile
  //   ? "https://asset.dev.amild.id/amild/static/cta_article_ub_m.png"
  //   : "https://asset.dev.amild.id/amild/static/cta_article_ub_d.png"

  const ctaSection = (
    <div
      className={clsx(
        "relative w-full h-fit",
        isMobile
          ? "px-[10px] mt-[20px] aspect-[355/274]"
          : "mt-[30px] aspect-[935/193]"
      )}
    >
      <Image
        alt="cta-article"
        className="rounded-[10px] overflow-hidden"
        src={ctaImg}
        // src="https://images.unsplash.com/photo-1620503292890-c597f62cce8d?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=600&ixid=MnwxfDB8MXxyYW5kb218MHx8cGlua3x8fHx8fDE3MTM0MTMyNjY&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=600"
      />
    </div>
  )

  const handleOnShow = () => {
    const forDatalayer = {
      event: "general_event",
      event_name: `page_reached_finish_on_read_article`,
      feature: data?.is_regular ? "simple engagement" : "read article",
      engagement_type: "content",
      engagement_name: "article",
      campaign_name: data?.is_special_engagement
        ? "why not 2025"
        : data?.campaign?.toLowerCase(), // e.g all
      event_label: `article - ${data?.title?.toLowerCase()}`,
      content_id: data?.id,
    }
    if (data?.is_special_engagement) {
      postMessage(
        { action: "datalayer", datalayer: forDatalayer },
        process.env.NEXT_PUBLIC_META_URL
      )
    } else {
      datalayer.push(forDatalayer)
    }
  }

  return (
    <>
      <ToastComponent
        onClick={() => {
          setPoint(false)
        }}
        title={titleBrand}
        desc="Lanjut eksplor dan kumpulkan lebih banyak poin!"
        onShow={() => handleOnShow()}
        isOpen={point && inView}
      />
      <div
        className={clsx(
          "w-full h-full relative",
          !isMobile && "max-w-[935px] mx-auto pt-[50px]",
          !isMobile && isTablet && "px-[16px] pt-[80px]"
        )}
      >
        {isMobile && content}
        <div
          className={clsx(
            "my-[20px] flex flex-col flex-wrap",
            isMobile ? "px-[15px]" : "px-0 mt-0"
          )}
        >
          {data?.point && (
            <div
              className={clsx(
                "flex items-center gap-[5px]",
                !isMobile && "mt-[20px] order-2"
              )}
            >
              {finished ? (
                <SuccessIcon />
              ) : (
                <div className="relative w-[24px] aspect-square">
                  <Image
                    alt="icon"
                    src={
                      data?.icon_url ||
                      `/assets/${process.env.NEXT_PUBLIC_NAME}/icons/reward-profile.svg`
                    }
                  />
                </div>
              )}
              <span className="text-text-1 font-bold font-font-family-7">
                {data?.point} Poin
              </span>
            </div>
          )}
          <h1
            className={clsx(
              "font-bold font-font-family-7 my-[20px] text-text-2",
              isMobile ? "text-xl" : "mt-0 text-[32px]"
            )}
          >
            {data?.title}
          </h1>
          <div className="flex items-center gap-[10px] text-xs text-text-3">
            <div className="py-[5px] px-[10px] rounded-[10px] border-[1px] border-solid border-text-3 font-medium font-font-family-6 tracking-[0.14px] leading-normal w-max uppercase">
              {data?.campaign}
            </div>
            <div className="py-[5px] px-[10px] rounded-[10px] border-[1px] border-solid border-text-3 font-medium font-font-family-6 tracking-[0.14px] leading-normal w-max uppercase">
              {data?.category}
            </div>
            <div className="py-[5px] px-[10px] rounded-[10px] border-[1px] border-solid border-text-3 font-medium font-font-family-6 tracking-[0.14px] leading-normal w-max uppercase">
              {data?.sub_category}
            </div>
          </div>
        </div>
        {!isMobile && content}
        {isMobile && (
          <Separator
            className={cn(
              " h-[5px] mx-[15px] border-box rounded-[0]",
              "bg-cta-4"
            )}
          />
        )}
        <DescriptionArticle
          className={clsx(
            "text-sm text-text-2 mt-[30px]",
            isMobile && "px-[15px]"
          )}
          description={data?.article_content.replace(/(?:\r\n|\r|\n)/g, "<br>")}
        />
        {ctaImg && !isLoading && isLogin && (
          <Link href={data?.cta_redirect_url || "/"} passHref>
            {ctaSection}
          </Link>
        )}
        {ctaImg && !isLoading && !isLogin && (
          <button onClick={() => login()}>{ctaSection}</button>
        )}
      </div>
      <div ref={bottomRef} />
    </>
  )
}

ArticleDetails.propTypes = {
  data: PropTypes.object,
}

export const SuccessIcon = ({ className }) => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx={11.4473} cy={11} r={9} fill="#32D74B" />
      <path
        d="M7.44727 12.5L9.94727 14.5L15.4473 8"
        stroke="white"
        strokeWidth={1.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

SuccessIcon.propTypes = {
  className: PropTypes.string,
}
