import Portal from "@/components/shared/portal"
import { IconClose, IconWarning } from "../../components/submission-icons"
import { AnimatePresence, motion } from "framer-motion"
import { cn } from "@/lib/utils"
import { useEffect, useState } from "react"

const SnackbarWarning = ({ open = false, message = "Profil Band", close }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setIsOpen(true)
      }, 1000)
    }
  }, [open])

  const handleClose = () => {
    setIsOpen(false)

    setTimeout(() => {
      close()
    }, 1000)
  }

  return (
    <AnimatePresence>
      <Portal>
        <motion.div
          initial="closed"
          animate={isOpen ? "open" : "closed"}
          variants={variants}
          className={cn(
            "w-full max-w-[375px] fixed z-50",
            open ? "block" : "hidden"
          )}
        >
          <div
            className="px-[10px] py-[15px] rounded-[15px] flex gap-[10px]"
            style={bgDetail}
          >
            <div className="min-w-[5px] bg-[#FF9F0A] rounded-[5px]" />
            <div className="flex items-center gap-[10px]">
              <div>
                <IconWarning />
              </div>
              <div className="flex flex-col text-sm">
                <p className="font-bold font-font-family-7 text-text-4">
                  Warning
                </p>
                <div className="font-medium font-font-family-6 text-text-3">
                  <p>
                    {isDSS ? "Kamu belum" : "Lo belom"} lengkapin {message}{" "}
                    {isDSS ? "kamu" : "lo"}.
                  </p>
                  <p>
                    Kembali dan isi bagian ini untuk bisa submit karya{" "}
                    {isDSS ? "kamu" : "lo"}!
                  </p>
                </div>
              </div>
              <button type="button" onClick={handleClose}>
                <IconClose className="stroke-icon-3" width={24} height={24} />
              </button>
            </div>
          </div>
        </motion.div>
      </Portal>
    </AnimatePresence>
  )
}

const isDSS = process.env.NEXT_PUBLIC_NAME === "djisamsoe"
const bgDetail = {
  background:
    "linear-gradient(0deg, #2C2A29, #2C2A29), linear-gradient(180deg, rgba(255, 255, 255, 0.1) -41.6%, rgba(255, 255, 255, 0) 14.96%)",
}

const variants = {
  closed: {
    bottom: 0,
    left: "50%",
    x: "-50%",
    y: "125%",
  },
  open: {
    bottom: 64,
    left: "50%",
    x: "-50%",
    y: "0%",
  },
}

export default SnackbarWarning
