"use client"
import PropTypes from "prop-types"
import { useIsDesktop } from "@/hooks/use-is-mobile"
import { Image } from "@/components/shared/image/image"
import { cn } from "@/lib/utils"

const PreviewDetailBandProfile = ({ data, setIsShowOfficialImage }) => {
  const isDesktop = useIsDesktop()
  const isBandPhoto = data?.official_press_photo?.length
  const isBandLogo = data?.band_logo?.length

  const handleGenre = () => {
    const genre = data?.band_genre || data?.band_profile?.genre
    const sub_genre = data?.sub_genre || data?.band_profile?.subgenre
    return sub_genre ? `${genre}-${sub_genre}` : genre
  }

  const bandDetailClass = () => {
    const isBiography = data?.band_biography || data?.band_profile?.biography
    let className = isBiography
      ? "relative -mt-[100px] px-[10px]"
      : "absolute bottom-[5px] px-[10px]"

    return (isBandPhoto || isBandLogo) && className
  }

  return (
    <div
      className={cn(
        "relative rounded-[15px] lg:rounded-[20px] lg:rounded-tl-none overflow-y-auto",
        isBandPhoto || isBandLogo ? "px-[10px] py-[15px]" : "p-5"
      )}
      style={{ background: isDesktop ? "#191919" : bgDetail }}
    >
      {!isDesktop && (
        <div className="text-text-4 font-bold font-font-family-7 mb-[15px]">
          Tentang Penyanyi
        </div>
      )}

      <div className="relative">
        {/* BAND PHOTO */}
        {isBandPhoto || isBandLogo ? (
          <div className="relative">
            <div className="flex h-[355px]">
              <Image
                src={
                  data?.official_press_photo[0]?.preview ||
                  data?.band_logo[0]?.preview
                }
                alt="Official Press"
                data-testid="Official Press"
                className="rounded-[10px]"
                onLoad={() => {
                  URL.revokeObjectURL(
                    data?.official_press_photo[0]?.preview ||
                      data?.band_logo[0]?.preview
                  )
                }}
              />
            </div>

            {isBandPhoto > 1 && (
              <div className="absolute top-[15px] right-[10px] px-[10px] py-[5px] rounded-full text-sm font-medium font-font-family-5 bg-[#eaeaea]/15">
                1/{isBandPhoto}
              </div>
            )}

            {/* OVERLAY */}
            <button
              className="absolute inset-0"
              style={{
                background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 39.83%, rgba(0, 0, 0, 0.8) 82.12%, ${isDesktop ? "#191919" : "#2C2A29"} 100%)`,
              }}
              disabled={!isBandPhoto && !isBandLogo}
              onClick={() => setIsShowOfficialImage(true)}
            />
          </div>
        ) : null}

        {/* BAND DETAIL */}
        <div className={cn("z-10", bandDetailClass())}>
          <div className="flex items-center gap-[15px]">
            {isBandLogo ? (
              <div className="w-[70px] h-[70px] min-w-[70px] rounded-full overflow-hidden">
                <Image
                  src={data?.band_logo[0]?.preview}
                  alt="Logo Band"
                  data-testid="Logo Band"
                  onLoad={() => {
                    URL.revokeObjectURL(data?.band_logo[0]?.preview)
                  }}
                />
              </div>
            ) : null}
            <div className="flex flex-col gap-[5px] text-text-4">
              <p className="text-xl font-black font-font-family-8">
                {data?.band_name || data?.band_profile?.band_name}
              </p>
              <div className="flex flex-wrap gap-[5px] text-sm font-normal capitalize">
                <p>{(data?.city || data?.band_profile?.city)?.toLowerCase()}</p>
                <span>|</span>
                <p>{handleGenre()}</p>
              </div>
            </div>
          </div>

          {(data?.band_biography || data?.band_profile?.biography) && (
            <>
              <div className="w-full h-[2px] bg-white/10 rounded-full my-[15px]" />
              <div className="flex flex-col gap-[5px]">
                <span className="text-sm text-text-4 font-bold font-font-family-7">
                  Biography
                </span>
                <p className="text-sm text-text-4 opacity-85 whitespace-pre-line">
                  {data?.band_biography || data?.band_profile?.biography}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
PreviewDetailBandProfile.propTypes = {
  data: PropTypes.object.isRequired,
  setIsShowOfficialImage: PropTypes.func.isRequired,
}

export const bgDetail =
  "linear-gradient(0deg, #2C2A29, #2C2A29), linear-gradient(180deg, rgba(255, 255, 255, 0.1) -41.6%, rgba(255, 255, 255, 0) 14.96%)"

export default PreviewDetailBandProfile
