/* eslint-disable react-hooks/exhaustive-deps */
"use client"
import clsx from "clsx"
import { AnimatePresence, motion } from "framer-motion"
import { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useIsDesktop, useIsTablet } from "@/hooks/use-is-mobile"
import { PreviewAudioContext } from "../preview-audio"
import SnackbarWarning from "../snackbar-warning"
import PreviewDetailBandProfile from "./preview-detail-band-profile"
import PreviewDetailTab from "./preview-detail-tab"
import PreviewDetailSongAbout from "./preview-detail-song-about"

const tabDarker = {
  background:
    "linear-gradient(270deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.5) 100%), #191919",
}

const PreviewDetail = ({ isGallery }) => {
  const { setIsShowOfficialImage, data } = useContext(PreviewAudioContext)
  const isDesktop = useIsDesktop()
  const isTablet = useIsTablet()
  const [detailActive, setDetailActive] = useState(1)
  const [isShowMoreSong, setIsShowMoreSong] = useState(false)
  const [allowBandAbout, setAllowBandAbout] = useState(false)
  const [isAboutSongFilled, setIsAboutSongFilled] = useState(false)
  const [warning, setWarning] = useState({
    open: false,
    message: "Profil Band",
  })

  const listFieldRequired = ["band_name", "city", "band_genre"]
  const checkField = (list) =>
    list.map((field) =>
      data?.listFieldStatus?.[field] ? !!data[field]?.length : true
    )

  useEffect(() => {
    const isFilled = checkField(listFieldRequired)
    const isAllFilled = isFilled.every((fill) => fill)
    const isAboutSongFilled = data?.about_song || data?.caption

    setAllowBandAbout(isGallery || isAllFilled)
    setIsAboutSongFilled(isAboutSongFilled)
    setDetailActive(isAllFilled ? 1 : 2)
    setWarning({
      open: !isAllFilled || !isAboutSongFilled,
      message: !isAllFilled ? "Profil Band" : "Tentang Lagu",
    })
  }, [])

  return (
    <AnimatePresence>
      <motion.div
        key="detail"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 0.2, duration: 0.3 } }}
        exit={{ opacity: 0 }}
        transition={{ type: "Inertia" }}
        className={clsx(
          "w-full relative max-w-[375px] mx-auto",
          isDesktop && "overflow-hidden h-full",
          isTablet && "h-fit",
          !allowBandAbout && !isAboutSongFilled && "hidden"
        )}
      >
        <div
          className={clsx(
            "w-full h-full flex flex-col",
            !isDesktop && "gap-[15px]"
          )}
        >
          {isDesktop && (
            <div className="w-fit rounded-t-[20px] flex" style={tabDarker}>
              <PreviewDetailTab
                active={detailActive === 1}
                onClick={() => setDetailActive(1)}
                hidden={!allowBandAbout}
              >
                Tentang Penyanyi
              </PreviewDetailTab>
              <PreviewDetailTab
                active={detailActive === 2}
                onClick={() => setDetailActive(2)}
                hidden={!isAboutSongFilled}
              >
                Tentang Lagu Ini
              </PreviewDetailTab>
            </div>
          )}
          {(!isDesktop || detailActive === 1) && allowBandAbout ? (
            <PreviewDetailBandProfile
              data={data}
              setIsShowOfficialImage={setIsShowOfficialImage}
            />
          ) : null}
          {!isDesktop || detailActive === 2 ? (
            <PreviewDetailSongAbout
              data={data}
              isShowMoreSong={isShowMoreSong}
              setIsShowMoreSong={setIsShowMoreSong}
            />
          ) : null}
        </div>
      </motion.div>

      {/* WARNING */}
      <SnackbarWarning
        open={warning.open}
        message={warning.message}
        close={() => setWarning((prev) => ({ ...prev, open: false }))}
      />
    </AnimatePresence>
  )
}

PreviewDetail.propTypes = {
  isGallery: PropTypes.bool,
}

export default PreviewDetail
