/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import PropTypes from "prop-types"
import { IconCloseSquare, IconUpload } from "./submission-icons"
import { Image } from "@/components/shared/image/image"
import { cn } from "@/lib/utils"
import { addLogoCampaign } from "../audio-submission/hooks"

const imageType = {
  "image/jpeg": [],
  "image/png": [],
}

// TODO: BELUM DINAMIS DENGAN VIDEO
const SubmissionDropzone = ({
  submissionFiles,
  setSubmissionFiles,
  submissionErrors,
  setSubmissionErrors,
  setPreview,
  maxSubmission = 1,
  maxImageSize = 5000000,
  selected,
  setSelected,
  logoCampaign,
  isCover = false,
}) => {
  const [errors, setErrors] = useState(submissionErrors || "")

  useEffect(() => {
    if (setSubmissionErrors) setSubmissionErrors(errors)
  }, [errors])

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: maxSubmission - submissionFiles.length,
    maxSize: maxImageSize,
    accept: imageType,
    onDrop: async function (acceptedFiles, fileRejections) {
      const params = {
        list: acceptedFiles,
        isCover,
        logoCampaign: logoCampaign,
      }
      const formattedFiles = await addLogoCampaign(params)
      setSubmissionFiles(submissionFiles.concat(formattedFiles))

      if (fileRejections.length === 0) {
        setErrors("")
      } else {
        fileRejections.forEach((file) => {
          file.errors.forEach((err) => {
            if (err.code === "file-too-large") {
              setErrors(`Ukuran foto terlalu besar`)
            } else if (err.code === "file-invalid-type") {
              setErrors(`Format file tidak sesuai`)
            } else if (err.code === "too-many-files") {
              setErrors(`Foto tidak boleh lebih dari ${maxSubmission}`)
            }
          })
        })
      }
    },
  })

  const thumbs = () => {
    return submissionFiles?.map((file, index) => {
      let isSelected = index === selected
      return (
        <div className="relative w-fit h-fit" key={file?.preview || file}>
          <div className="relative rounded-[10px] overflow-hidden w-[88px] h-[88px]">
            {isSelected && selected === index && (
              <div className="absolute top-0 left-0 z-[1] w-[58px] py-[4px] pl-[8px] bg-bg-5 text-[10px] italic text-text-4 font-medium leading-[11.72px] rounded-br-[16px]">
                Selected
              </div>
            )}

            {/* BUTTON DELETE IMAGE */}
            {file.isRemovable ? (
              <button
                type="button"
                className="absolute top-[3px] right-[3px] z-[1] w-[24px]"
                onClick={() => {
                  removeFile(file)
                  if (typeof setSelected === "function" && selected === index)
                    setSelected(null)
                }}
              >
                <IconCloseSquare />
              </button>
            ) : null}

            <button
              type="button"
              data-testid="preview-btn"
              className={cn(
                "relative rounded-[10px] overflow-hidden border-2 w-[88px] h-[88px]",
                isSelected && "border-cta-1"
              )}
              onClick={() => {
                if (typeof setSelected === "function" && selected !== index) {
                  setSelected(index)
                  setErrors("")
                  if (setSubmissionErrors) setSubmissionErrors("")
                } else {
                  setPreview({
                    open: true,
                    file: file,
                  })
                }
              }}
            >
              <Image
                data-testid={`thumb-preview-${index}`}
                src={file?.preview || file}
                alt="pic"
                /* Revoke data uri after image is loaded */
                onLoad={() => {
                  URL.revokeObjectURL(file.preview)
                }}
              />
            </button>
          </div>
        </div>
      )
    })
  }

  const removeFile = (file) => {
    const resultFile = submissionFiles.filter(
      (media) => media.preview !== file.preview
    )
    setSubmissionFiles(resultFile)
  }

  return (
    <>
      {/* DROPZONE */}
      {submissionFiles.length < maxSubmission && (
        <div className="relative w-fit h-fit">
          <div
            data-testid="drop-input"
            {...getRootProps({
              className:
                "w-[88px] h-[88px] dropzone rounded-[5px] border-[1px] border-dashed border-cta-1 overflow-hidden flex flex-col justify-center items-center p-[11px] cursor-pointer",
            })}
          >
            <input data-testid="drop-input" {...getInputProps()} />
            <IconUpload />
            <span
              className={
                "text-text-1 text-center mt-[3px] font-medium font-font-family-6 text-xs tracking-[0.25px]"
              }
            >
              Upload Gambar
            </span>
          </div>
        </div>
      )}

      {/* THUMBNAIL */}
      {submissionFiles?.length > 0 && (
        <div className="flex items-center gap-[5px]">{thumbs()}</div>
      )}
    </>
  )
}

SubmissionDropzone.propTypes = {
  submissionFiles: PropTypes.object,
  setSubmissionFiles: PropTypes.func,
  submissionErrors: PropTypes.string,
  setSubmissionErrors: PropTypes.func,
  setPreview: PropTypes.func,
  maxSubmission: PropTypes.number,
  maxImageSize: PropTypes.number,
  selected: PropTypes.number,
  setSelected: PropTypes.func,
}

export default SubmissionDropzone
