"use client"
import React from "react"
import PropTypes from "prop-types"
import { cn } from "@/lib/utils"

export function OfflineEngagementLayout({ children }) {
  return (
    <div
      className={cn(
        "relative min-h-[calc(100dvh-56px)] bg-bg-1 pt-0 min-h-[100dvh-56px] max-w-[100dvw] overflow-hidden",
      )}
    >
      {children}
    </div>
  )
}

OfflineEngagementLayout.propTypes = {
  children: PropTypes.object,
}
