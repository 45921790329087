/* eslint-disable react-hooks/exhaustive-deps */
"use client"
import { AnimatePresence, motion } from "framer-motion"
import PropTypes from "prop-types"
import { RemoveScroll } from "react-remove-scroll"
import { IconArrowDown } from "../../components/submission-icons"
import clsx from "clsx"
import { useIsDesktop, useIsTablet } from "@/hooks/use-is-mobile"
import ModalAudioPlayer from "../audio-player"
import { createContext, useEffect, useMemo, useRef, useState } from "react"
import { Image } from "@/components/shared/image/image"
import { useWindowSize } from "@/hooks/use-window-size"
import UpvoteButton from "../../upvote-button/upvote-button"
import { ClientApiList } from "@/lib/api/client-service"
import PreviewOfficialPress from "./preview-official-press"
import PreviewDetail from "./preview-detail/preview-detail"

export const PreviewAudioContext = createContext()

const PreviewAudio = ({
  open,
  close,
  data: passedData,
  isGallery,
  setDataMusic,
  setSelectedAudio,
  dataAudio,
  selectedAudio = {},
}) => {
  const { width } = useWindowSize()
  const isDesktop = useIsDesktop()
  const isTablet = useIsTablet()
  const isDesktopTablet = width > 640 && width < 1024
  const isMobile = width <= 640
  const [onMouseEnter, setOnMouseEnter] = useState(false)
  const [onLoad, setOnLoad] = useState(false)
  const [isShowOfficialImage, setIsShowOfficialImage] = useState(false)
  const [isTitleShort, setIsTitleShort] = useState(false)
  const data = { ...passedData, ...selectedAudio }
  const containerRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    if (!open) return
    const differentWidth =
      textRef?.current?.offsetWidth - containerRef?.current?.offsetWidth
    const duration = Math.trunc(differentWidth / 100) * 5
    setIsTitleShort(differentWidth < 1)

    textRef.current.style = `
      --marquee_pingpong-duration: ${duration || 10}s;
      --marquee_pingpong-distance: -${differentWidth}px;
    `
  }, [open, width])

  const handleClose = () => {
    close()
  }

  const bgBtn = () => {
    if (data?.voted === 1) return "var(--cta_3)"
    else if (data?.voted === 0) return "var(--cta_2)"
  }

  const onVote = async () => {
    setOnLoad(true)
    try {
      const { data: dataSubmit } = await ClientApiList.getGalleryVote({
        vote: data?.voted === 0 ? "vote" : "unvote",
        submission_uuid: data?.uuid,
        type: "audio",
      })

      if (dataSubmit.data) {
        setOnLoad(false)
        setDataMusic(
          dataAudio.map((audio) => {
            if (audio.uuid === data?.uuid) {
              setSelectedAudio({
                ...audio,
                voted: data?.voted == 0 ? 1 : 0,
                total_vote:
                  data?.voted === 0
                    ? parseInt(data?.total_vote) + 1 || 0 + 1
                    : data?.total_vote - 1,
              })
              return {
                ...audio,
                voted: data?.voted == 0 ? 1 : 0,
                total_vote:
                  data?.voted === 0
                    ? parseInt(data?.total_vote) + 1 || 0 + 1
                    : data?.total_vote - 1,
              }
            }
            return audio
          })
        )
      }
    } catch (err) {
      setOnLoad(false)
    }
  }

  const contextValue = useMemo(
    () => ({
      isShowOfficialImage,
      setIsShowOfficialImage,
      data,
    }),
    [isShowOfficialImage, setIsShowOfficialImage, data]
  )

  return (
    <PreviewAudioContext.Provider value={contextValue}>
      <AnimatePresence>
        {open && (
          <div className="fixed inset-0 z-[20] bg-black overflow-hidden">
            <RemoveScroll
              className={clsx(
                "w-full h-[calc(100dvh-56px)]",
                !isShowOfficialImage && "overflow-y-auto overscroll-contain"
              )}
            >
              <motion.div
                key="modal"
                initial={{
                  y: "100%",
                }}
                animate={{
                  y: 0,
                  transition: { delay: 0.2, duration: 0.3 },
                }}
                exit={{
                  y: "100%",
                }}
                transition={{ type: "Inertia" }}
                style={{ originX: 0 }}
                className={clsx(
                  "relative w-full lg:h-full pt-[40px] pb-[30px] px-[20px] sm:px-[40px] lg:px-[80px] flex gap-[40px] md:gap-[32px]",
                  isTablet && "flex-col"
                )}
              >
                {/* AUDIO PLAYER */}
                <div className="w-full h-full flex flex-col">
                  <div className="flex relative">
                    <button
                      onClick={handleClose}
                      className="absolute top-0 left-0"
                    >
                      <IconArrowDown className={"stroke-icon-4"} />
                    </button>
                    <div className="flex-1 text-text-4 text-center font-bold font-font-family-7">
                      Preview Lagu
                    </div>
                  </div>

                  <div className="w-full h-full pt-[40px] flex flex-col items-center gap-[25px] pb-[40px]">
                    <div
                      className={clsx(
                        "relative rounded-[15px] overflow-hidden h-full aspect-square",
                        isDesktop
                          ? "w-auto max-w-[235px] min-w-[235px] max-h-[235px] min-h-[235px]"
                          : "w-full max-w-[335px] max-h-[335px]"
                      )}
                    >
                      {data?.cover && (
                        <Image
                          src={data?.cover}
                          alt="Cover Song"
                          data-testid="Cover Song"
                          onLoad={() => {
                            URL.revokeObjectURL(data?.cover)
                          }}
                        />
                      )}
                    </div>
                    <div
                      ref={containerRef}
                      className={clsx(
                        "relative",
                        !isDesktop && "w-full",
                        !isTitleShort && "overflow-hidden"
                      )}
                    >
                      <div
                        className={clsx(
                          "flex flex-col gap-[15px] w-full lg:w-auto",
                          !isMobile && "items-center text-center"
                        )}
                      >
                        <div
                          className={clsx(
                            "relative",
                            isDesktopTablet && isTitleShort
                              ? "w-auto"
                              : "w-full",
                            isDesktop && "max-w-[450px]"
                          )}
                        >
                          <p
                            ref={textRef}
                            className="w-fit animate-marquee_pingpong whitespace-nowrap text-2xl md:text-4xl font-bold font-font-family-7 text-text-4 capitalize"
                          >
                            {data?.song_title || data?.title || "Unknown"}
                          </p>
                        </div>
                        <p
                          className="text-sm font-medium font-font-family-6 md:text-xl md:font-font-family-5"
                          style={{ color: "rgba(255, 255, 255, 0.8)" }}
                        >
                          {data?.band_name ||
                            data?.band_profile?.band_name ||
                            "Unknown"}
                        </p>
                      </div>
                      {isGallery ? (
                        <UpvoteButton
                          onClick={() => onVote()}
                          style={{ background: bgBtn() }}
                          className={clsx(
                            "flex justify-center items-center p-[10px] rounded-[100px] text-[12px] leading-[18px] hover:text-text-1 duration-300 bg-cta-2 h-[38px] absolute",
                            isDesktop && isTitleShort
                              ? "left-[calc(100%+15px)] top-0"
                              : "bottom-0 right-0",
                            data?.voted === 1
                              ? "text-text-1"
                              : "text-text-2 border border-cta-4",
                            onLoad ? "cursor-wait" : "cursor-pointer"
                          )}
                          onLoad={onLoad}
                          setOnMouseEnter={setOnMouseEnter}
                          vote={data?.voted}
                          onMouseEnter={onMouseEnter}
                        />
                      ) : null}
                    </div>
                  </div>

                  <ModalAudioPlayer
                    file={data?.audio[0]}
                    isOpen={open}
                    isAutoPlay={open && isGallery}
                  />
                </div>

                {/* DETAILS */}
                <PreviewDetail isGallery={isGallery} />
              </motion.div>
            </RemoveScroll>

            {/* COVER AS BACKGROUND */}
            <div className="absolute inset-0 -z-10">
              <div className="absolute inset-0 bg-black/75 backdrop-blur-xl" />
              <Image
                src={data?.cover}
                alt="Cover Song"
                data-testid="Cover Song"
                className="object-top"
                onLoad={() => {
                  URL.revokeObjectURL(data?.cover)
                }}
              />
            </div>
          </div>
        )}
      </AnimatePresence>

      <PreviewOfficialPress />
    </PreviewAudioContext.Provider>
  )
}

PreviewAudio.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  data: PropTypes.object,
  isGallery: PropTypes.bool,
  setDataMusic: PropTypes.func,
  setSelectedAudio: PropTypes.func,
  dataAudio: PropTypes.object,
  selectedAudio: PropTypes.object,
}

export default PreviewAudio
