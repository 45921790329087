import { toProxy } from "@/helper/utils"

export const addLogoCampaign = async (parameter) => {
  const {
    list,
    isCover = false,
    processBlob = false,
    isRemovable = true,
    logoCampaign = null,
  } = parameter

  if (!list) return

  try {
    const files = processBlob
      ? await Promise.all(list.map(fetchImageUrl))
      : list
    const updatedFiles = await addPreviewsToFiles(files, isRemovable)
    const mergedImages = isCover
      ? await mergeImages(updatedFiles, logoCampaign)
      : updatedFiles?.map((file) => file?.preview)
    const finalFiles = await addPreviewsToFiles(
      updatedFiles,
      isRemovable,
      mergedImages
    )

    return finalFiles
  } catch (error) {
    console.error("Error processing images:", error)
  }
}

const fetchImageUrl = async (url) => {
  const response = await fetch(url)
  const blob = await response.blob()
  return new File([blob], "image.png", {
    type: "image/png",
  })
}

// Convert files to base64 and add `preview`
const addPreviewsToFiles = async (files, isRemovable, newImages = []) => {
  return Promise.all(
    files.map(
      (file, index) =>
        new Promise((resolve) => {
          const reader = new FileReader()
          reader.onloadend = () =>
            resolve(
              Object.assign(file, {
                preview: newImages[index] || reader.result,
                isRemovable: isRemovable,
              })
            )
          reader.readAsDataURL(file)
        })
    )
  )
}

// Merge images with the logo
const mergeImages = async (files, logoCampaign) => {
  return Promise.all(
    files.map(async (file) => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")

      const baseImage = await loadImage(file.preview)
      canvas.width = baseImage.width
      canvas.height = baseImage.height
      ctx.drawImage(baseImage, 0, 0)

      if (logoCampaign) {
        const files = await fetchImageUrl(toProxy(logoCampaign))
        const updatedFiles = await addPreviewsToFiles([files], false)
        const logo = await loadImage(updatedFiles[0]?.preview)

        // Skala logo agar lebarnya 20% dari lebar base image
        const scaleFactor = 0.35
        const logoWidth = baseImage.width * scaleFactor

        // Menyesuaikan tinggi logo agar sesuai dengan rasio aslinya
        const aspectRatio = logo.naturalHeight / logo.naturalWidth
        const logoHeight = logoWidth * aspectRatio

        // Padding dinamis (5% dari lebar base image)
        const padding = baseImage.width * 0.05
        const logoX = baseImage.width - logoWidth - padding
        const logoY = baseImage.height - logoHeight - padding

        ctx.drawImage(logo, logoX, logoY, logoWidth, logoHeight)
      }

      return canvas.toDataURL("image/png")
    })
  )
}

// Helper function to load images
const loadImage = (src) =>
  new Promise((resolve) => {
    const img = new Image()
    img.src = src
    img.onload = () => resolve(img)
  })
