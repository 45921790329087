"use client"
import YourChallengeCard from "../user-profile-leaderboard/components/your-challenge-card/your-challenge-card"
import BackBanner from "../shared/back-banner"
import ButtonBack from "../shared/button-back/button-back"
import { useIsMobile } from "@/hooks/use-is-mobile"

export function YourChallenge({
  bg_img_url_mobile,
  bg_img_url_desktop,
  title,
  leaderboard_img_url,
  cta_label,
  subtitle,
  description,
  section_title,
  redirect_url,
  periode,
}) {
  const isMobile = useIsMobile()
  const isMagnum = process.env.NEXT_PUBLIC_NAME === "magnum"

  return (
    <div className="max-w-[636px] mx-auto lg:!max-w-[50%] lg:w-full overflow-hidden px-[10px] lg:p-[15px] lg:pr-[6px] pb-[10px] lg:m-0 relative z-[5] lg:max-h-[624px] lg:sticky lg:top-[64px]">
      <BackBanner
        className="hidden lg:block oveflow-hidden lg:top-[15px] lg:left-[15px] lg:z-[-1] lg:max-h-[609px] h-full lg:p-0 "
        bgImg={isMobile ? bg_img_url_mobile : bg_img_url_desktop}
        imgClass={"!object-top"}
      >
        <ButtonBack className="lg:left-[15px] lg:top-[15px]" />
      </BackBanner>
      <div className="hidden w-fit mx-auto lg:block text-[20px] lg:text-[24px] font-medium font-font-family-6 text-center text-black leading-[27px] lg:leading-[32px] pt-[23px] mb-[186px] text-text-2">
        Leaderboard
      </div>
      <div className="text-[20px] lg:text-[24px] leading-[27px] lg:leading-[32px] font-medium font-font-family-6 mb-[10px] text-text-2 lg:hidden">
        {section_title}
      </div>
      <YourChallengeCard
        {...{
          title: title,
          challengeImg: leaderboard_img_url,
          linkLabel: cta_label,
          subtitle: subtitle,
          description: description,
          periode: periode,
          redirect_url,
          reward_style: isMagnum,
        }}
      />
    </div>
  )
}
