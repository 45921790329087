"use client"
import React from "react"
import { Image } from "@/components/shared/image/image"

// custom props import
import PopupAction from "@/components/shared/popup-action/popup-action"
import ButtonAction from "@/components/shared/button-action/button-action"
import { useIsMobile } from "@/hooks/use-is-mobile"
import { usePathname, useRouter, useSearchParams } from "next/navigation"

const successPopup = {
  title: `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Tebakan kamu benar!" : "Tebakan lo benar!"}`,
  description:
    "Mainkan kembali tantangan untuk bisa berkesempatan menjadi pemenang dengan memiliki EXP tertinggi.",
  cta_label: "Eksplor Tantangan Selanjutnya",
  cta_cancel: "Cek keseruan lainnya",
}
const failedPopup = {
  title: `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Waktu Kamu Habis!" : "Waktu Lo Habis!"}`,
  description: `${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "Kamu harus lebih cepat buat nyusun gambar yang tepat. Jangan khawatir kamu bisa ulang terus sampai dapet gambar yang tepat" : "Lo harus lebih cepat buat nyusun gambar yang tepat. Jangan khawatir lo bisa ulang terus sampai dapet gambar yang tepat"}`,
  cta_label: "Coba Lagi",
}

export function PopupPuzzle({
  isShowing,
  status = "completed",
  popupData = {},
  resetPuzzle,
  point,
  nextUrl,
  handleShowPopupExit,
  isOnHold,
}) {
  // console.log(nextUrl);
  const isMobile = useIsMobile()
  const pathname = usePathname()
  const { replace, push } = useRouter()
  const copyText = status === "completed" ? successPopup : failedPopup
  const searchParams = useSearchParams()
  const params = [...searchParams.entries()].filter(
    (param) => param[0] !== "show-puzzle-popup"
  )
  const joinedParams = params.map((param) => param.join("=")).join("&")

  const url = status !== "completed" ? pathname + "?" + joinedParams : "/br"

  return (
    <PopupAction
      isShowing={isShowing}
      href={`${url}`}
      onClickBtnX={() =>
        status !== "completed" ? handleShowPopupExit(true) : null
      }
      zIndex="z-[20]"
    >
      <div className="max-w-[304px]">
        <div className="absolute rounded-[10px] overflow-hidden inset-0 z-[-1] "></div>
        <div className="absolute rounded-[10px] border-[3px] border-cta-3 border-dotted inset-[10px] z-[-1]"></div>
        <div className="min-w-[304px] min-h-[177px] bg-bg-5 rounded-[10px] overflow-hidden relative mb-[20px]">
          <Image
            data-testid="popup-image"
            alt="cover"
            fill
            style={{ objectFit: "cover", objectPosition: "center" }}
            className="absolute inset-0"
            src={
              popupData
                ? isMobile
                  ? popupData[
                      `mobile_img_url_${status === "completed" ? "confirm" : "timeout"}`
                    ]
                  : popupData[
                      `desktop_img_url_${status === "completed" ? "confirm" : "timeout"}`
                    ]
                : null
            }
          />
        </div>
        <div
          data-testid="popup-title"
          className="text-[20px] font-black font-font-family-8 leading-[27px] mb-[5px] text-text-2"
        >
          {
            popupData[
              `subtitle_${status === "completed" ? "confirmation" : "timeout"}`
            ]
          }
        </div>
        {point ? (
          <div className="flex items-center gap-[5px] justify-center mb-[5px]">
            {/* <div className="w-fit bg-[#FEE9EA] shadow-[0px_2px_12px_1px_rgba(237,28,36,0.08),0px_1px_2px_0px_rgba(237,28,36,0.10)] text-text-5 mx-auto mb-[15px] py-[10px] px-[20px] rounded-[100px] flex items-center gap-[5px] border-b-[2px] border-text-1"> */}
            <div className="min-h-[20px] w-fit min-w-[20px] relative">
              <Image
                alt="cover"
                fill
                style={{
                  objectFit: "cover",
                  objectPosition: "center",
                  position: "absolute",
                  inset: 0,
                }}
                src={`/assets/${process.env.NEXT_PUBLIC_NAME}/icons/${process.env.NEXT_PUBLIC_NAME === "djisamsoe" ? "reward.png" : "reward-profile.svg"}`}
              />
            </div>
            <div
              data-testid="popup-score"
              className="text-[20px] text-text-1 leading-[27px] font-black font-font-family-8"
            >
              {point} Poin!
            </div>
          </div>
        ) : null}

        <div
          data-testid="popup-description"
          className="text-[14px] text-text-2 md:text-[16px] font-medium font-font-family-6 mb-[20px] leading-[20px] md:leading-[25px]"
        >
          {
            popupData[
              `desc_${status === "completed" ? "confirmation" : "timeout"}`
            ]
          }
        </div>

        {status === "completed" ? (
          <>
            {nextUrl ? (
              <ButtonAction
                onClick={() => {
                  isOnHold
                    ? replace(
                        pathname +
                          `?holdstep=brandpref&link=${encodeURIComponent(nextUrl)}&hide-puzzle-rule=true&show-puzzle-popup=true`
                      )
                    : replace(nextUrl)
                }}
                className=" mb-[10px]"
                intent="primary"
              >
                {copyText.cta_label}
              </ButtonAction>
            ) : null}
            <ButtonAction
              data-testid="popup-cancel"
              onClick={() => push("/br")}
              className=""
              intent="secondary"
            >
              {copyText.cta_cancel}
            </ButtonAction>
          </>
        ) : (
          <ButtonAction
            onClick={() => {
              resetPuzzle()
            }}
            className=" mb-[10px]"
            intent="primary"
          >
            {copyText.cta_label}
          </ButtonAction>
        )}
      </div>
    </PopupAction>
  )
}
